import React, { useEffect, useState } from "react";
import { ContainerImg } from "./generiStyled";
import styled from "styled-components";
import { Col, Row } from "antd";
import { CloseCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Theme } from "../../genericComponents/globalStyles";
import { UploadFirebaseImage } from "../../../helpers/helperFuntion";
import ImgProductDefault from '../../../assets/img/bxsShopping.svg'
import CusttomSpin from "../../genericComponents/CusttomSpin";


export default function ImagesProduct({
  images,
  setFieldValue,
  setFieldTouched,
  idStore,
}) {
  const [imgView, setImgView] = useState();
  const [urlNew, setUrlNew] = useState();
  const [loadingImg, setLoadingImg] = useState(false)
 
  useEffect(() => {
    if (images?.length > 0) {
      setImgView(images[0]);
    } else {
      setImgView("");

    }
  }, [images , urlNew]);

  function Preview(url) {
    setImgView(url);
  }


  async function uploadingImages(e) {
    setLoadingImg(true)
    const url = (await UploadFirebaseImage(
      e.target.files, `/products/${idStore}/${(new Date()).getTime()}${e.target.files[0].name}`
    ))
    aggImagen(url)
    setUrlNew(url)
    setLoadingImg(false)
  }

  function aggImagen(urlNew) {
    setFieldValue('images', [urlNew, ...images])
  }

  function deleteImg(urlNew) {
    const newListImg = images.filter(result => result !== urlNew)
    setFieldValue('images', newListImg)
  }




  return (
    <ContainerImg>
      <Row justify="center">
        <Col span={24}>
          {
            loadingImg? (
              <>
                <CusttomSpin heigthSpin={'40vh'}/>
              </>
            ) : (
                <>
                  <ContainerImgView>
                    {
                      imgView ? (
                        <ImgViewProduct urlImg={imgView} />
                      ) : (
                          <ImgViewProductDefault />
                        )
                    }
                  </ContainerImgView>
                </>
              )
          }
        </Col>
        <Col span={24}>
          <ContainerImgOption>
            {
              images && images.length < 5 && (
                <ImgOptionUpload>
                  <UploadButton
                    id="img"
                    type="file"
                    placeholder="+ Subir Imagen"
                    multiple
                    onChange={uploadingImages}
                  />
                  <UploadLabel for="img">
                    <PlusCircleOutlined />
                  </UploadLabel>
                </ImgOptionUpload>
              )
            }

            <ContainerListImg>
              {images && (
                images?.map((item) => (

                  <ListImg
                    url={item}
                    onClick={() => Preview(item)}
                  >
                    <BtnDeleteImg
                      onClick={(e) => {
                        e.preventDefault()
                        deleteImg(item)
                      }
                      }
                    >
                      <CloseCircleFilled />
                    </BtnDeleteImg>
                  </ListImg>
                ))
              )}
            </ContainerListImg>
          </ContainerImgOption>
        </Col>
      </Row>
    </ContainerImg>
  );
}

const ContainerImgView = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeee;
`;


const Images = styled.div`
  width: 85%;
  height: 85%;
  margin: auto;
  border: 1px solid #eee;
  background-size: contain;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

const ImgViewProduct = styled(Images)`
  background-image: url(${(props) => props.urlImg});

`;

const ImgViewProductDefault = styled(Images)`
  background-image: url(${ImgProductDefault});
  background-color: #fff;

`;

const ContainerImgOption = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding: 5px; 

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  img {
    max-width: 120px;
    max-height: 15vh;
    border: 1px solid #eee;
    margin: 10px;
    cursor: pointer;
  }
`;

const ImgOptionUpload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: #989191;
  font-weight: 200px;
  margin: 0px 10px;

  :hover{
    color: ${Theme.color.primary};
  }

`;

const UploadButton = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const ContainerListImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListImg = styled.div`
  background-image: url(${(props) => props.url});
  background-position: center;
  width: 70px;
  height: 70px;
  background-size: cover;
  margin: 5px;
  border: 1px solid #eeee;
  display: flex;
  justify-content: flex-end;
 

`;


const BtnDeleteImg = styled.button`
  height: 10px;
  border: none;
  cursor: pointer;
  color: ${Theme.color.cancel};
  font-size: 0.9rem;
  :hover{
    font-size: 1rem;
  }
`;
