import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Button(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 } }spin />;
  return (
    <CustomButton {...props}>
      {props.loading ? (<CustomLabel><SpinC indicator={antIcon} /></CustomLabel>) : (<CustomLabel {...props} >{props.children}</CustomLabel>)}
    </CustomButton>
  )
}

const CustomButton = styled.button`
  width: ${({width}) => width || '100%'};
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  flex: ${({flex}) => flex || '1'};
  text-align: center;
  padding: 5px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props)=> props.boxShadow || 'none'};
  transition-duration: 0.4s;
  :hover{
    background-color: ${(props)=> props.hover};
    width: ${({widthHover}) => widthHover || '98%'};
  }

`;

const CustomLabel = styled.label`
  margin:auto;
  cursor: pointer;
  text-align: center;
  font-size: 1.5em;
  color: ${(props) => props.color || '#000'};

`;


const SpinC = styled(Spin)`&&&{
  color: #fff;
}
  
`;
