import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Menu, Dropdown } from 'antd';
import { Theme } from './globalStyles';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/img/LogoMenu.png';
import {
  CaretDownOutlined
} from '@ant-design/icons';
import firebase from 'firebase';
import MenuMonile from './MenuMobile';
import { Context } from '../../globalContext/globalContext';
import HeaderSearch from './HeaderSearch';


export default function HeaderMenu(props) {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  function SignOut() {
    firebase.auth().signOut()
      .then((r) => {
        history.replace('/auth');
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const menuUser = (
    <Menu>
      <Menu.Item>
        <ButtonNav {...props} onClick={() => history.push('/change/password')} >Cambiar contraseña</ButtonNav>
      </Menu.Item>
      <Menu.Item>
        <ButtonNav onClick={SignOut}>Cerrar Sesión</ButtonNav>
      </Menu.Item>
    </Menu>
  )

  const {state:{user}} = useContext(Context);
  const {role:roleUser} = user;


  return (
    <>
      <ContainerMenu>
        <Row >
          <Col xs={24} xl={24}>
            <MenuNav >
              <ContainerLogo>
                <span onClick={() => history.push('/')} ><Logo src={logo} /></span>
              </ContainerLogo>
              <MenuMonile />
              <UlNav>
                <li>
                  <HeaderSearch
                    open={open}
                    setOpen={setOpen}
                    value={value}
                    setValue={setValue}
                    placeholder={"Que estas buscando? "}
                    onSearch={() => alert("searching")}
                  />
                </li>
                <li>
                  <ButtonNavH  {...props} onClick={() => history.push('/')}>Inicio</ButtonNavH>
                </li>

                {
                  roleUser === "admin" ? (
                    <>
                      <li>
                        <ButtonNavSs {...props} onClick={() => history.push('/admin/stores')} >Tiendas</ButtonNavSs>
                      </li>
                      <li>
                        <ButtonNavC {...props} onClick={() => history.push('/admin/categories')} >Categorias</ButtonNavC>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <ButtonNavS {...props} onClick={() => history.push('/store')} >Mi tienda</ButtonNavS>
                      </li>
                      <li>
                        <ButtonNavI {...props} onClick={() => history.push('/inventory')}>Inventario</ButtonNavI>
                      </li>
                    </>

                  )

                }


                {/* <li>
                  <ButtonNavSe  {...props} onClick={() => history.push('/setting')}>Configuraciones</ButtonNavSe>
                </li> */}

                <Dropdown overlay={menuUser} trigger={['click']}>
                  <ButtonNav className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <CaretDownOutlined />
                  </ButtonNav>
                </Dropdown>
              </UlNav>
            </MenuNav>
          </Col>
          <Col xs={24} xl={24}>
            <Panel {...props}>{props.children}</Panel>
          </Col>
        </Row>
      </ContainerMenu>
    </>
  );
}

const ContainerMenu = styled.div`
  width:100%;
`;

const MenuNav = styled.div`
  box-shadow: 0 5px 10px 0 rgba(158, 158, 158, 0.5);
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index:100;
  margin-bottom: 0; 
  
`;

const ContainerLogo = styled.div`
  padding: 10px 40px;
`;

const Logo = styled.img`
  width: 100px;
  cursor: pointer;
`;

const UlNav = styled.ul`
  list-style: none;
  display:flex;
  margin-bottom: 0;
  @media (max-width: 850px){
    display: none;
  }
`;


const Panel = styled.div`
  width: 100%;
  margin: 100px auto 100px auto;
  @media(max-width:850px){
    margin-top:100px;
  }
  
`;

const ButtonNav = styled.button`
  text-align:center;
  color: ${Theme.color.sencondary};
  background-color: #ffffff;
  border:none;
  padding: 12px 20px;
  font-size: 1.5em;
  cursor:pointer;
  outline: none;
  margin: 0;
  border: solid 1px #fff ;
  
  @media(max-width:900px){
    font-size: 1.3em;
  }

  :hover{
    border-bottom: 1px solid ${Theme.color.primary};
    
  }

`;

const ButtonNavH = styled(ButtonNav)`
    background-color: ${(props) => props.backgroudColorH || '#fff'};
    color: ${(props) => props.colorH || Theme.color.secundary};

`;

const ButtonNavS = styled(ButtonNav)`
  background-color: ${(props) => props.backgroudColorS || '#fff'};
  color: ${(props) => props.colorS || Theme.color.secundary};

`;

const ButtonNavI = styled(ButtonNav)`
  background-color: ${(props) => props.backgroudColorI || '#fff'};
  color: ${(props) => props.colorI || Theme.color.secundary};

`;



const ButtonNavSs = styled(ButtonNav)`
  background-color: ${(props) => props.backgroudColorSs || '#fff'};
  color: ${(props) => props.colorSs || Theme.color.secundary};

`;

const ButtonNavC = styled(ButtonNav)`
  background-color: ${(props) => props.backgroudColorC || '#fff'};
  color: ${(props) => props.colorC || Theme.color.secundary};

`;
