import React, { useContext, useState } from 'react';
import { Theme } from '../../genericComponents/globalStyles';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Col, Row, message } from 'antd';
import styled from 'styled-components';
import CardCategories from '../components/CardCategories';
import AddCategory from '../components/ModalAddCategorie/AddCategorie';
import Button from '../../genericComponents/Button';
import { Context } from '../../../globalContext/globalContext';
import { useMutation } from '@apollo/client';
import { INSERT_CATEGORY, UPDATE_CATEGORY } from '../../../helpers/graphqlMutation';
import CusttomSpin from '../../genericComponents/CusttomSpin';

export default function CategoriesScreen() {
  const { state: { categories }, dispatch: { setCategories } } = useContext(Context);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataToEditCategory, setDataToEditCategory] = useState();
  const [loading, setLoading] = useState(false)


  const [update_category, { loading: loadingCategory }] = useMutation(
    UPDATE_CATEGORY, {
    onCompleted: onCompletedUpdate, onError: onErrorUpdate,
  });




  function onCompletedUpdate(data) {
    message.success("¡ Categoria actualizada!");

    const categoryEdit = (
      categories?.map((category) => {

        if (category?.categories_id === data?.update_categories_by_pk.categories_id) {
          return {
            ...category,
            title: data?.update_categories_by_pk?.title,
            exa_color: data?.update_categories_by_pk?.exa_color,
            image: data?.update_categories_by_pk?.image

          }
        } else {
          return category
        }


      })
    )

    setCategories(categoryEdit)

  }

  function onErrorUpdate(data) {
    console.log("error", data);
  }

  const [insert_categories, { loading: loadingInsertCategory }] = useMutation(
    INSERT_CATEGORY, {
    onCompleted: onCompletedInsert, onError: onErrorInsert,
  });


  function onCompletedInsert(data) {
    message.success("¡Nueva Categoria agregada correctamente!");

    const newCategory = [
      {
        categories_id: data?.insert_categories?.returning[0]?.categories_id,
        title: data?.insert_categories?.returning[0]?.title,
        exa_color: data?.insert_categories?.returning[0]?.exa_color,
        image: data?.insert_categories?.returning[0]?.image,
        products: [],


      },
      ...categories
    ]

    setCategories(newCategory);



}

function onErrorInsert(data) {
  console.log(data)
}


const showModal = () => {
  setIsModalVisible(true);
};



const handleCancel = () => {
  setIsModalVisible(false);
};

const  handleOkCategory = async  (categoryId, nameCategory, hexaColor, imgUrl) => {

  if(nameCategory){
    setIsModalVisible(false);
    if(loading) return
    setLoading(true)
    if (categoryId) {
      await update_category({
        variables: {
          categories_id: categoryId,
          title: nameCategory,
          exa_color: hexaColor,
          image: imgUrl,
        }
      })
    } else {
      await insert_categories({
        variables: {
          title: nameCategory,
          exa_color: hexaColor,
          image: imgUrl,
        }
      })
    }

    setLoading(false)

  }else{
    message.warning("por favor ingrese el nombre para la categoria");

  }

  
  
}





return (
  <>
    <HeaderMenu backgroudColorC={Theme.color.primary} colorC={'#fff'} />

    <Container>
      <Row justify="center" >
        <Col xs={22} sm={20} lg={18} xl={16} >

          <Row justify="end" >
            <Col xs={10} sm={8} lg={6} xl={6}>
              <Button
                backgroundColor={Theme.color.acept}
                color={"#fff"}
                onClick={
                  () => {
                    showModal();
                    setDataToEditCategory({})
                  }
                }

              >Agregar categoria</Button>
            </Col>
          </Row>


          <Row justify="center" >

            <Col xs={24} sm={24} md={24} xl={24}>
              <ContainerCategories>
                {
                  loadingCategory || loadingInsertCategory ? (<CusttomSpin heigthSpin={'40vh'} />) : (
                    <CardCategories
                      categories={categories}
                      showModal={showModal}
                      setDataToEditCategory={setDataToEditCategory}
                    />
                  )
                }
              </ContainerCategories>
            </Col>

          </Row>


        </Col>
      </Row>
    </Container>


    <AddCategory
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      handleOkCategory={handleOkCategory}
      dataToEditCategory={dataToEditCategory}

    />
  </>
)
}

const Container = styled.div`
  
`;

const ContainerCategories = styled.div`
  background-color:#fcfcfc;
  width: 100%;
  min-height: 40vh;
  margin: 30px auto;
  border-radius: 10px;
  padding: 20px 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;