import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
  minHeigth: '200px'
};


export class MapContainer extends React.Component {

  render(

  ) {
    return (
      <>

        <Map

          google={this.props.google}
          zoom={this.props.zoom || 6}
          style={mapStyles}
          initialCenter={
            {
              lat: (this.props.latitude || -33.0000000),
              lng: (this.props.longitude || -56.0000000)
            }
          }
          center={
            {
              lat: (this.props.latitude || -33.0000000),
              lng: (this.props.longitude || -56.0000000)
            }
          }

        >
          <Marker
            title={this.props.name || "Nueva Tienda"}
            position={{
              lat: this.props.latitude,
              lng: this.props.longitude
            }} /> 

        </Map>

      </>
    );

  }


}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

})(MapContainer);

