import React, { useState } from 'react'
import { Row, Col } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import moment from 'moment';
import {
  Card,
  TextSubTitle,
  TimePickerC,
  ContainerInput,
  Table,
  TableTh,
  TableTd,


} from './generiStyled';


export default function Schedules({ schedule, setFieldValue }) {

  const [monday, setMonday] = useState(true);
  const [tuesday, setTuesday] = useState(true);
  const [wednesday, setWednesday] = useState(true);
  const [thursday, setThursday] = useState(true);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);
  const [sunday, setSunday] = useState(true);
  return (
    <Card>
      <Row gutter={[20, 16]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary} >Horarios</TextSubTitle>
        </Col>
        <ContainerInput>
          <Table>
            <tr>
              <TableTh>
                <input type="checkbox"
                  onChange={(active) => {
                    if (active.currentTarget.checked) {
                      setMonday(false)
                      setTuesday(false)
                      setWednesday(false)
                      setThursday(false)
                      setFriday(false)
                      setSaturday(false)
                      setSunday(false)
                    } else {
                      setMonday(true)
                      setTuesday(true)
                      setWednesday(true)
                      setThursday(true)
                      setFriday(true)
                      setSaturday(true)
                      setSunday(true)
                    }

                  }}
                /></TableTh>
              <TableTh>Dias laborables</TableTh>
              <TableTh>Hora de apertura</TableTh>
              <TableTh>Hora de cierre</TableTh>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!monday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.monday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setMonday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.monday
                      setFieldValue('schedule', newSchedule)
                      setMonday(true)
                    }

                  }}
                /></TableTd>
              <TableTd>Lunes</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={monday}
                  value={moment(schedule?.monday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.monday = {
                      ...newSchedule.monday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}

                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={monday}
                  value={moment(schedule?.monday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.monday = {
                      ...newSchedule.monday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!tuesday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.tuesday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setTuesday(false)

                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.tuesday 
                      setFieldValue('schedule', newSchedule)
                      setTuesday(true)

                    }

                  }}
                /></TableTd>
              <TableTd>Martes</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={tuesday}
                  value={moment(schedule?.tuesday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.tuesday = {
                      ...newSchedule.tuesday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={tuesday}
                  value={moment(schedule?.tuesday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.tuesday = {
                      ...newSchedule.tuesday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!wednesday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.wednesday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setWednesday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.wednesday 
                      setFieldValue('schedule', newSchedule)
                      setWednesday(true)
                    }

                  }}
                ></input></TableTd>
              <TableTd>Miercoles</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={wednesday}
                  value={moment(schedule?.wednesday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.wednesday = {
                      ...newSchedule.wednesday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={wednesday}
                  value={moment(schedule?.wednesday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.wednesday = {
                      ...newSchedule.wednesday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />

              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!thursday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.thursday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setThursday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.thursday
                      setFieldValue('schedule', newSchedule)
                      setThursday(true)
                    }

                  }}

                /></TableTd>
              <TableTd>Jueves</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={thursday}
                  value={moment(schedule?.thursday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.thursday = {
                      ...newSchedule.thursday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}

                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={thursday}
                  value={moment(schedule?.thursday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.thursday = {
                      ...newSchedule.thursday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!friday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.friday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setFriday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.friday
                      setFieldValue('schedule', newSchedule)
                      setFriday(true)
                    }

                  }}
                /></TableTd>
              <TableTd>Viernes</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={friday}
                  value={moment(schedule?.friday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.friday = {
                      ...newSchedule.friday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={friday}
                  value={moment(schedule?.friday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.friday = {
                      ...newSchedule.friday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!saturday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.saturday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setSaturday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.saturday  
                      setFieldValue('schedule', newSchedule)
                      setSaturday(true)
                    }

                  }}
                /></TableTd>
              <TableTd>Sabado</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={saturday}
                  value={moment(schedule?.saturday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.saturday = {
                      ...newSchedule.saturday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}

                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={saturday}
                  value={moment(schedule?.saturday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.saturday = {
                      ...newSchedule.saturday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
            </tr>
            <tr>
              <TableTd>
                <input type="checkbox"
                  checked={!sunday}
                  onChange={(active) => {
                    if (active.currentTarget.checked === true) {
                      const newSchedule = { ...schedule }
                      newSchedule.sunday = {
                        openTime: '08:00',
                        closeTime: '16:00',
                      }
                      setFieldValue('schedule', newSchedule)
                      setSunday(false)
                    } else {
                      const newSchedule = { ...schedule }
                      delete newSchedule.monday
                      setFieldValue('schedule', newSchedule)
                      setSunday(true)
                    }

                  }}
                /></TableTd>
              <TableTd>Domingo</TableTd>
              <TableTd>
                <TimePickerC
                  disabled={sunday}
                  value={moment(schedule?.sunday?.openTime || '08:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.sunday = {
                      ...newSchedule.sunday,
                      openTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }}
                />
              </TableTd>
              <TableTd>
                <TimePickerC
                  disabled={sunday}
                  value={moment(schedule?.sunday?.closeTime || '16:00', 'HH:mm')}
                  format='HH:mm'
                  onChange={(_, newHour) => {
                    const newSchedule = { ...schedule }
                    newSchedule.sunday = {
                      ...newSchedule.sunday,
                      closeTime: newHour
                    }
                    setFieldValue('schedule', newSchedule)
                  }} 
                />
              </TableTd>
            </tr>
          </Table>
        </ContainerInput>
      </Row><br />
    </Card>
  );
}


