import React from 'react';
import styled from 'styled-components';
import imgPaguinaConstruccion from '../../assets/img/en_construccion.svg';

export default function PagConstrucion(){
  return(
    <>
      <Container>
        <Img src={imgPaguinaConstruccion}/>
      </Container>
    </>
  )
}


const Img = styled.img`
  width: 500px;
  @media(max-width: 800px){
    width: 300px;
  }
  
`


const Container = styled.div`
  width: 100%;
  margin:auto;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  

`
