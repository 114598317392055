import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../genericComponents/globalStyles';
import Column from 'antd/lib/table/Column';
import Disable from './Disable';
import Enable from './Enable';
import { useMutation } from '@apollo/client';
import { CHANGE_STORES_STATUS } from '../../../helpers/graphqlMutation';
import imgStore from '../../../assets/img/bxsStore.svg'
import CusttomSpin from '../../genericComponents/CusttomSpin'
export default function StoresTable({
  storesList,
  refetchStoresList,
  Storesloading

}) {
  const [changeStoresStatus] = useMutation(
    CHANGE_STORES_STATUS,{onCompleted}
  );

  function onCompleted(e){
    console.log(e)
    refetchStoresList()
  } 

  return (
    <>

      {
        !Storesloading ? (
        <TableCusttom
        rowKey={record => record.key}
        dataSource={storesList}
        pagination={{ pageSize: 30, position: ['bottomCenter'] }}
        scroll={{ y: 440, x: 900 }}
      >
        <Column
          title='Foto'
          dataIndex='image_url'
          key='image_url'
          width='120px'
          fixed='left'
          render={(photo) =>
            <ContainerPhotoImg>
              {
                photo? (
                  <PhotoStore
                  urlImg={photo}
                  />
                ):(<PhotoStoreDeafult/>)
              }
            </ContainerPhotoImg>}
        />

        <Column
          title='Tienda'
          dataIndex='name'
          key='name' 
        />

        <Column
          title='Description'
          dataIndex='description'
          key='description'
          render={text => <label>
            {text?.length > 10 ? `${text.substring(0, 80)}...` : text || "Sin descripción"}
          </label>}
        />

        <Column
          title='Nº de Prodcuto'
          dataIndex='products'
          key='products'
          render={text => <LabelNumberProducts>
            {text.length || "Sin productos"}
          </LabelNumberProducts>}
        />

        <Column
          dataIndex='is_active'
          key='is_active'
          render={
            (_, record) => (
              <>
                {
                  record.is_active ? (
                    <Disable
                      onClick={
                        () => {
                          changeStoresStatus({
                            variables:{
                              is_active: false,
                              stores_id: record.stores_id
                            }
                          })
                        }
                      }
                    />
                  ) : (
                      <Enable
                        onClick={
                          () => {
                            changeStoresStatus({
                              variables:{
                                is_active: true,
                                stores_id: record.stores_id
                              }
                            })
                          }
                        }
                      />
                    )
                }
              </>
            )
          }
        />
      </TableCusttom>
        ):(
          <>
            <CusttomSpin heigthSpin={'40vh'}/>
          </>
        )  
    }
    </>
  )
}

const TableCusttom = styled(Table)`
  tr th, tr td{
    text-align: center;
    
  }

  tr th {
    color:#fff;
    background-color: ${Theme.color.primary};
    font-size: 1.3em;
  }

`;

const ContainerPhotoImg = styled.div`
  max-width: 100%;  
`;

const Images = styled.div`
  width: 70px;
  height: 70px;
  margin: auto;
  border: 1px solid #eee;
  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

const PhotoStore = styled(Images)`
  background-image: url(${(props) => props.urlImg});

`;

const PhotoStoreDeafult =  styled(Images)`
  background-image: url(${imgStore});
  
`;

const LabelNumberProducts = styled.label`
  color: ${Theme.color.primary};

`;




