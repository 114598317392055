import React from 'react';
import { Col, Input, Row } from 'antd';
import { InputNum } from '../../genericComponents/InputNum';
import { Theme  } from '../../genericComponents/globalStyles';
import {
  ContainerInput,
  TextSubTitle,
  TextInputTitle,
  CustomTextArea,
} from './generiStyled'; 
import WarningFomr from '../../genericComponents/WarningForm';


export default function ProductData({
  name, 
  description, 
  price, 
  setFieldValue, 
  touched, 
  errors , 
  setFieldTouched 
}){
  

  return(
    <ContainerInput>
      <Row gutter={[8, 10]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary}>Datos del Producto</TextSubTitle>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <TextInputTitle>Producto* </TextInputTitle>
          <Input 
            placeholder="Nombre del producto"  
            required
            value={name} 
            onChange={(e)=>setFieldValue('name',e.target.value)} 
            onBlur={() => setFieldTouched('name', true)}
          />
          {errors.name && touched.name && (<WarningFomr>{errors.name} </WarningFomr >)}
        </Col>
        <Col xs={24} sm={24} md={12}>
          <TextInputTitle>Precio*</TextInputTitle>
          <InputNum
            formatter={price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}  
            placeholder="000" 
            onChange={(e)=>setFieldValue('price',e)} 
            value={price}
            required
            onBlur={() => setFieldTouched('price', true)}
          />
          {errors.price && touched.price && (<WarningFomr>{errors.price} </WarningFomr >)}
        </Col>
        <Col span={24}>
          <TextInputTitle>Descripción*</TextInputTitle>
          <CustomTextArea 
            showCount 
            required
            minLength={30}
            maxLength={1200}
            autoSize= {{minRows: 2, maxRows: 4 }}
            value={description} 
            onChange={(e)=>setFieldValue('description',e.target.value)}  
            onBlur={() => setFieldTouched('description', true)}
          />
          {errors.description && touched.description && (<WarningFomr>{errors.description} </WarningFomr >)}
        </Col>
      </Row>
    </ContainerInput>
  );

}

