import React, { useContext,  useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Input} from 'antd';
import Button from '../../genericComponents/Button';
import { Theme } from '../../genericComponents/globalStyles';
import GoogleApiWrapper from '../../genericComponents/Maps';
import { useFormik } from 'formik';
import { SignUpSchema } from '../../../helpers/validationSchema';
import WarningFomr from '../../genericComponents/WarningForm';
import { toast } from 'react-toastify';
import { CREATE_STORE_ACCOUNT } from '../../../helpers/graphqlMutation';
import { useMutation } from '@apollo/client';
import 'firebase/auth';
import firebase from 'firebase';
import { autocompletadoMaps , getDetailsAddress } from '../../../helpers/helperFuntion';
import { Context } from '../../../globalContext/globalContext';
import { v4 as uuidv4 } from 'uuid';
import AutocompleteDirection from '../../genericComponents/AutocompleteDirection';
import { FormAuth, TextInputTitle, TextTitle, TextSubTitle } from './generyStyled';


export default function SingUp() {
  const sessionToken = uuidv4;
  const { apolloClient } = useContext(Context);
  const [timer, setTimer] = useState();
  const [directionList, setDirectionList] = useState()
  const [directionEnabled, setDirectionEnabled] = useState(true)
  const [listDitailsAdress, setListDitailsAdress] = useState()  
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      direction: '',
      password: '',
      passwordConfirm: '',
      longitude: -75.0604312,
      latitude: 4.7135786,
    },
    onSubmit,
   validationSchema: SignUpSchema
  });

  const [createStoreAccount, { loading }] = useMutation(
    CREATE_STORE_ACCOUNT, { onCompleted, onError }
  );

  function onCompleted(data) {
    if (data.createStoreAccount) {
      firebase.auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((r) => {window.location.replace('/product/create');})
      .catch((e) => {
        if (e.code === 'auth/user-not-found'){
          toast.error('Usuario no registrado');
          }else if (e.code === 'auth/wrong-password') {
            toast.error('Correo y Contraseña no coiciden');
          }else {
            toast.error('Ha ocurrido un error intente de nuevo');
          }
        })
    }
  }

  function onError(data) {
    console.log("data error", data)
  }

  function onSubmit(value ) {
    const { password, passwordConfirm } = value;
    if (password === passwordConfirm ) {
      createStoreAccount({
        variables: {
          name: value.name,
          email: value.email,
          direction: value.direction,
          password: value.password,
          longitude: value.longitude,
          latitude: value.latitude,
        }
      })

    } else {
      toast.error('Error las contraseñas no coiciden');
    }


    console.log(value)
  }

  function autoDirection(e) {
    clearTimeout(timer);
    setTimer(
      setTimeout( async() => {
      setDirectionList( await  autocompletadoMaps(e.target.value, sessionToken, apolloClient))
      }, 800)
    )
    setFieldValue('direction', e.target.value);
  }

  async function  detailsAddress(e){
   setListDitailsAdress( await getDetailsAddress(e , sessionToken, apolloClient))
  }
  return (
    <>
      <Row justify="center" >
        <Col xs={24} sm={20} md={16} lg={14} xl={12} >
          <FormAuth onSubmit={handleSubmit}>
            <Row gutter={[16, 8]}>
              <Col>
                <TextTitle color={Theme.color.primary}>Registro</TextTitle>
              </Col>
            </Row>

            <Row gutter={[20, 16]} justify="center">
              <Col span={24}>
                <TextSubTitle color={Theme.color.primary}>Datos de la tienda</TextSubTitle>
              </Col>
              <Col xs={22} md={12}>
                <TextInputTitle>Nombre de la tienda* </TextInputTitle>
                <Input
                  placeholder="Nombre de la tienda"
                  required
                  values={values.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  onBlur={() => setFieldTouched('name', true)}
                />
                {errors.name && touched.name && (<WarningFomr>{errors.name} </WarningFomr>)}

              </Col>
              <Col xs={22} md={12}>
                <TextInputTitle>Correo electrónico*</TextInputTitle>
                <Input
                  placeholder="Example@gmail.com"
                  required
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  onBlur={() => setFieldTouched('email', true)}
                />
                {errors.email && touched.email && (<WarningFomr>{errors?.email}</WarningFomr>)}
              </Col>
            </Row>

            <Row gutter={[20, 16]} justify='center'>
              <Col span={24}>
                <TextSubTitle color={Theme.color.primary} fontSize={'1.8em'}>Password</TextSubTitle>
              </Col>
              <Col xs={22} md={12}>
                <TextInputTitle>Contraseña*</TextInputTitle>
                <Input.Password
                  placeholder="Ingrese Contraseña"
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  onBlur={() => setFieldTouched('password', true)}
                />
                {errors.password && touched.password && (<WarningFomr>{errors?.password}</WarningFomr>)}
              </Col>
              <Col xs={22} md={12}>
                <TextInputTitle>Confirmar Contraseña*</TextInputTitle>
                <Input.Password
                  placeholder="Confirmen Contraseña"
                  value={values.passwordConfirm}
                  onChange={(e) => setFieldValue('passwordConfirm', e.target.value)}
                  onBlur={() => setFieldTouched('passwordConfirm', true)}
                />
                {errors.passwordConfirm && touched.passwordConfirm && (<WarningFomr>{errors?.passwordConfirm}</WarningFomr>)}
              </Col>
            </Row>
            <Row gutter={[0, 16]} justify="center">
              <Col span={24}>
                <TextSubTitle color={Theme.color.primary}>Dirección</TextSubTitle>
              </Col>
              <Col xs={22} md={24}>
                <TextInputTitle>Ingrese su Dirección*</TextInputTitle>
                <AutocompleteDirection
                  onChange={autoDirection}
                  directionList={directionList}
                  value={values.direction}
                  sessionToken={sessionToken}                  
                  onDirectionSelected={(direction , placeID)=> {
                      setFieldValue('direction' , direction)
                      setDirectionEnabled(false)
                      detailsAddress(placeID)
                  }}
                  directionEnabled={directionEnabled}
                  setDirectionEnabled={setDirectionEnabled}
                />
                {errors.direction && touched.direction && (<WarningFomr>{errors?.direction}</WarningFomr>)}
              </Col>

              <Col xs={22} md={24} >
                <ContainerMaps>
                  <GoogleApiWrapper 
                  longitude={listDitailsAdress?.lng}
                  latitude={listDitailsAdress?.lat}
                  name={values.name}
                  />
                </ContainerMaps>
              </Col>
            </Row><br />

            <Row gutter={[16, 0]} justify="center">
              <Col xs={20} sm={8} md={10} >
                <Button
                  type='submint'
                  backgroundColor={Theme.color.acept}
                  color={'#fff'}
                  loading={loading ? true : undefined}
                >Registrar</Button>
              </Col>
            </Row>

          </FormAuth>
        </Col>
      </Row>
    </>
  );
}


const ContainerMaps = styled.div`
  width: 100%;
  height: 250px;
  background-color: #f1efef;

`;