import { gql } from "@apollo/client";


export const USERS = gql`
  query($users_id1: String! ){
    users_by_pk(users_id: $users_id1) {
      role
      email
      users_id  
    }
  }


`

export const PRODUCTS = gql`
  query($email:String!){
    products(where: {store: {user: {email: {_eq: $email }}}}) {
      name
      description
      products_id
      price
      images_list
      products_images {
        image_url
        products_images_id
      }
      category {
        title
        categories_id
        sub_categories {
          title
          sub_categories_id
        }
      }
      
    }categories {
      categories_id
      title
    }

  }


`
export const PRODUCT = gql`

  query ($products_id: uuid!) {
    products(where: {products_id: {_eq: $products_id}}) {
      name
      price
      products_id
      description
      main_category
      sub_category
      images_list
      products_images {
        image_url
        products_images_id
      }
      category {
        categories_id
        title
        sub_categories {
          title
          sub_categories_id
        }
      }
    }
    
    categories {
      categories_id
      title
    }
    
  }

`
export const INITIAL_QUERY = gql`
  query ($userId:String!){
    
    categories {
      categories_id
      title
      exa_color
      image
      products {
       name
      }
      sub_categories {
        sub_categories_id
        title
        products {
          name
          products_id
        }
      }
    }

    stores(where: {users_id: {_eq: $userId}}) {
      direction
      longitude
      description
      latitude
      instagram_user
      name
      phone_number
      direction_details
      image_url
      open_time
      website
      whatsapp_phone
      close_time
      schedule
      stores_id
        
        user{
          email
        }
    }

  
  }
`



export const AUTOCOMPLETEDIRECTION = gql`
  query (
    $direction: String!,
    $sessionToken: String!
  ){
    autocompleteDirections(
      direction: $direction, 
      sessionToken: $sessionToken
    ){
      description
      placeId
    }
  }
`

export const DIRECTIONDETAILS = gql`
  query(
    $placeID: String!,
    $sessionToken: String!
  ) {
      directionDetails(
        placeID: $placeID, 
        sessionToken: $sessionToken
      ){
        formatted_address
        location {
          lat
          lng
        }
      }
    }

`

export const STORES = gql`
  query {
  stores (order_by: {name: asc}) {
    description
    image_url
    is_active
    name
    stores_id
    products {
      products_id
    }
  }
}

`

export const CATEGORIES = gql`
  query{
    categories {
      categories_id
      title
      exa_color
      image
      products {
      name
      }
      sub_categories {
        sub_categories_id
        title
        products {
          name
          products_id
        }
      }
  }
  }

`

export const SUBCATEGORY = gql `

  query (
    $categories_id: uuid!
  ) {
  sub_categories(where: {categories_id: $categories_id}) {
    title
    sub_categories_id
  }
}
`
