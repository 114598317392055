import React from 'react';
import styled from 'styled-components';
import { Theme } from './globalStyles';


export default function WarningFomr({...props}){
  return(
    
    <ContainerLabel>
      <LabelWarning {...props}>{props.children}</LabelWarning>
    </ContainerLabel>
    
  )
}

const LabelWarning = styled.label`
  color: ${Theme.color.cancel};
  margin:auto;
  

`;

const ContainerLabel = styled.div`
  border-top: 1px solid ${Theme.color.cancel};
  text-align: center;
`;