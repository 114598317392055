import { useMutation, useQuery } from '@apollo/client';
import { Col, message, Popconfirm, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../../globalContext/globalContext';
import { CREATE_PRODUCT, UPDATE_PRODUCT } from '../../../helpers/graphqlMutation';
import { PRODUCT } from '../../../helpers/graphqlQuerys';
import { ProductSchema } from '../../../helpers/validationSchema';
import Button from '../../genericComponents/Button';
import CusttomSpin from '../../genericComponents/CusttomSpin';
import { Theme } from '../../genericComponents/globalStyles';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import CategoryProduct from '../components/CategoryProduct';
import ProductData from '../components/ProductData';
import ImagesProduct from '../components/ImagesProduct';
import { Botones } from '../components/generiStyled';


export default function ProductScreen() {
  const history = useHistory();
  const { id } =  useParams();
  let { path } = useRouteMatch();
  const { state: { store } } = useContext(Context);
  const idStore = store?.stores_id;
  
 
  
  const { data, loading, refetch } = useQuery(
    PRODUCT, {
    variables: { products_id: id }, skip: (path === '/product/create')
  })
  
  const [createProduct, { loading: loadingCreateProduct }] = useMutation(
    CREATE_PRODUCT, { onCompleted, onError } ,
  );

  const [updateProduct] = useMutation(
    UPDATE_PRODUCT, {
    onCompleted: onCompletedUpdate, onError: onErrorUpdate,
    variables: {
      product_id: id
    }
  } ,
  );

  function onCompletedUpdate(data) {
    message.success("¡Producto actualizado!")
    history.push('/inventory');
  }

  function onErrorUpdate(data) {
    console.log(data);
  }


  function onCompleted(data) {
    message.success("Se añadio un nuevo producto a la lista")
    refetch();
    history.push('/inventory');
  }

  function onError(data) {
  }

  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      categorySelected: '',
      subcategorySelectd: '',
      idStore: idStore,
      images_list: '',
    },
    onSubmit,
    validationSchema: ProductSchema
  });

  function onSubmit(value) {
    if (id) {
      message.loading('Guardando cambios...', .5);
      updateProduct({
        variables: {
          name: value.name,
          description: value.description,
          price: value.price,
          category: value.categorySelected,
          images_list: value.images,
          sub_category: value.subcategorySelectd,
        }
      })

    } else {
      message.loading('Creando nuevo producto...', 1);
      createProduct({
        variables: {
          name: value.name,
          description: value.description,
          price: value.price,
          category: value.categorySelected,
          storeId: store?.stores_id,
          images_list: value.images,
          sub_category: value.subcategorySelectd,
        }
      })

    }
  }

  useEffect(() => {
    setFieldValue('name', data?.products[0]?.name || '');
    setFieldValue('description', data?.products[0]?.description || '');
    setFieldValue('price', data?.products[0]?.price || '' );
    setFieldValue('categorySelected', data?.products[0]?.main_category );
    setFieldValue('images', data?.products[0]?.images_list || []);
    setFieldValue('subcategorySelectd', data?.products[0]?.sub_category);
    
  }, [data , setFieldValue])

  function cancel(e) {
    console.log(e);
  }

  return (
    <HeaderMenu backgroudColorI={Theme.color.primary} colorI={'#fff'}>
      <ContainerProduct onSubmit={handleSubmit} >
        <Row justify="center"  >
          <Col xs={24} sm={22} md={22} lg={18} xl={14}  >
            {!loading && !loadingCreateProduct && (
              <Row justify="center" gutter={[20, 20]}>
                <Col xs={24} sm={24} md={14 } lg={10} >
                  <ImagesProduct
                    idStore={idStore}
                    images={values.images}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                
                </Col>
                <Col xs={24} sm={24} md={14} lg={14}>
                  <Row >
                    <Col md={24} lg={24} >
                      <ProductData
                        name={values.name}
                        description={values.description}
                        price={values.price}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                    <Col span={24} >
                      <CategoryProduct
                        categorySelected={values.categorySelected}
                        subcategorySelectd={values.subcategorySelectd}
                        listCategories={data?.categories}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        loading
                        touched={touched}
                        errors={errors}

                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

            )}

            {loading && (
              <CusttomSpin heigthSpin={'40vh'} />
            )}

            <Botones>
              <Row gutter={[20, 20]} justify="center" >
                <Col xs={14} sm={6} md={8} >
                  <Button
                    type='submint'
                    backgroundColor={Theme.color.acept}
                    color={'#fff'}
                    hover={Theme.color.aceptHover}
                    boxShadow={Theme.color.aceptBoxShadow}
                  >Guardar</Button>
                </Col>
                <Col xs={14} sm={6} md={8}>

                  {id ? (
                    <Popconfirm
                      title="¿Desea descartar los cambios?"
                      onConfirm={() => history.push('/inventory')}
                      onCancel={cancel}
                      okText="Si"
                      cancelText="No"
                    ><Button
                      backgroundColor={Theme.color.cancel}
                      color={'#fff'}
                      hover={Theme.color.cancelHover}
                      boxShadow={Theme.color.cancelBoxShadow}
                    >Cancelar</Button></Popconfirm>
                  ) : (
                      <Button
                        backgroundColor={Theme.color.cancel}
                        color={'#fff'}
                        hover={Theme.color.cancelHover}
                        boxShadow={Theme.color.cancelBoxShadow}
                        onClick={(() => history.push('/inventory'))}
                      >Cancelar</Button>
                    )}


                </Col>
              </Row>
            </Botones>
          </Col>
        </Row>
      </ContainerProduct>
    </HeaderMenu>
  )
}

const ContainerProduct = styled.form`
    width: 100%;
    margin: auto;
`;

