import React, { useContext, useEffect , useState} from 'react';
import { GlobalStyle } from './genericComponents/globalStyles';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { 
  Slide, 
  ToastContainer 
} from "react-toastify";
import { routes } from '../helpers/routes';
import 'firebase/auth';
import firebase from 'firebase/app';
import { Context } from '../globalContext/globalContext';
import { INITIAL_QUERY, USERS } from '../helpers/graphqlQuerys';
import CusttomSpin from './genericComponents/CusttomSpin';
import ProductScreen from './product/screens/productScreen';
import HomeScreen from './main/screens/HomeScreen';
import AuthScreen from './auth/screens/AuthScreen';
import StoreScreen from './store/screens/StoreScreens';
import InvetoryScreen from './inventory/screens/InventoryScreen';
import SettingScreen from './setting/screens/SettignScreen';
import Paguina404 from './genericComponents/Paguina404';
import ResetPassword from './auth/components/ResetPassword';
import ChangePassword from './auth/components/ChangePassword';
import StoresScreens from './stores/screens/StoresScreens';
import CategoriesScreen from './categories/screens/CategoriesScreen';



function AuthRoute({ ...rest }) {
  const { isAuth } = rest;

  if (isAuth) {
    return (
      <Route {...rest} />
    );
  }else {
    return <Redirect to={routes.AUTH} />;
  }
  
}



function App() {
  const {dispatch:{setUser , setCategories, setStore} , apolloClient} = useContext(Context);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        apolloClient.query({query:INITIAL_QUERY , variables:{userId:user.uid}})
        .then((result)=>{
          setCategories(result?.data?.categories);
          setStore(result?.data?.stores[0])
        })
        .catch((errors)=>{
          console.log(errors)
        })


        apolloClient.query({query: USERS ,  variables:{users_id1: user.uid} })
        .then((result)=>{
          const {
            email,
            role,
            users_id
          } = result.data.users_by_pk
          
          setUser({
            email: email,
            role: role,
            id: users_id
          })
        })
        .catch((errors)=>{
          console.log("error" , errors)
        }) 
        
        setIsAuth(true);
      }else{
        setIsAuth(false);
      }


      setLoading(false);
    })
  },[apolloClient, setCategories, setStore, setUser])
  
  if(loading){
    return <CusttomSpin/>
  }
  else{
    return (
      <>
        <GlobalStyle/>
        <ToastContainer
          autoClose={3000}
          transition={Slide}
          newestOnTop
          closeOnClick
          pauseOnHover={true}
          draggable
        />
        <Router>
          <Switch>

            <Route path={routes.AUTH} component={AuthScreen}/>
            <Route path={routes.RESET_PASSWORD} component={ResetPassword}/>
            <AuthRoute exact path={routes.HOME} isAuth={isAuth} component={HomeScreen}/>
            <AuthRoute path={routes.STORE} isAuth={isAuth} component={StoreScreen} />
            <AuthRoute path={routes.INVENTORY} isAuth={isAuth} component={InvetoryScreen} />
            <AuthRoute path={routes.SETTING} isAuth={isAuth} component={SettingScreen} />
            <AuthRoute path={routes.PRODUCT_EDIT} isAuth={isAuth} component={ProductScreen}  />
            <AuthRoute path={routes.PRODUCT_CREATE} isAuth={isAuth} component={ProductScreen}  />
            <AuthRoute path={routes.CHANGE_PASSWORD } isAuth={isAuth} component={ChangePassword }/>
            <AuthRoute path={routes.ADMIN_STORES} isAuth={isAuth} component={StoresScreens}/>
            <AuthRoute path={routes.ADMIN_CATEGORIES} isAuth={isAuth}  component={CategoriesScreen}/>
            <Route path={routes.PAGE_NOT_FOUND} component={Paguina404}/>
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
