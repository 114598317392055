import React, { useState } from 'react';
import HeaderAuth from '../../genericComponents/HeaderAuth';
import { Theme } from '../../genericComponents/globalStyles';
import Button from '../../genericComponents/Button';
import 'firebase/auth';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import WarningFomr from '../../genericComponents/WarningForm';
import { resetPassSchema } from '../../../helpers/validationSchema';
import { Col, Row, Input, notification } from 'antd';
import { FormAuth, TextInputTitle, TextTitle } from './generyStyled';


export default function ResetPassword(){
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
    validationSchema: resetPassSchema
  });


  function onSubmit(data){
    const {email} = data;
    setLoading(true);
    firebase.auth().sendPasswordResetEmail(email)
    .then(function() {
      setLoading(false);
      notification.success({
        message: 'Correo enviado',
        description: 'Se envio a su correo electronico las instruciones para restablecer su contraseña'
      })

      history.replace('/auth');
    })
    .catch(function(error) {
      setLoading(false);
      if(error.code === 'auth/user-not-found'){
        notification.error({
          message: '¡ERROR!',
          description: 'Usuario no registrado'
        })
      }
      
      console.log(error)
    });
  }
  return(
    <>
      <HeaderAuth height={'30vh'}/>
      <Row justify="center">
        <Col xs={24} sm={18} md={12} ld={12} xl={10}>
          <FormAuth onSubmit={handleSubmit}>
            <Row gutter={[0, 20]}>
              <Col>
                <TextTitle color={Theme.color.primary}>Reestablecer contraseña</TextTitle>
              </Col>
            </Row><br/>

            <Row gutter={[0, 20]} justify="center">
              <Col xs={22} md={18}>
                <TextInputTitle>Correo Electrónico</TextInputTitle>
                <Input
                  required
                  placeholder="example@gmail.com"
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  onBlur={() => setFieldTouched('email', true)}
                />
                {errors.email && touched.email && (<WarningFomr>{errors?.email}</WarningFomr>)}
              </Col>
            </Row><br/>


            <Row gutter={[10, 4]} justify="center">
              <Col xs={20} sm={16} md={10} >
                <Button
                  loading={loading ? true : undefined}
                  type='submint'
                  backgroundColor={Theme.color.acept}
                  color={'#fff'}
                >Enviar</Button>
              </Col>
              <Col xs={20} sm={16} md={10} >
                <Button
                  loading={loading ? true : undefined}
                  backgroundColor={Theme.color.cancel}
                  color={'#fff'}
                  onClick={()=> history.replace('/auth')}
                >Cancelar</Button>
              </Col>
            </Row><br /><br /><br />
          </FormAuth>
        </Col>
      </Row>
    </>
  )
}


