import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './helpers/initialize';
import { ApolloProvider } from '@apollo/client';
import { client } from './helpers/ApolloClient';
import { Provider } from './globalContext/globalContext';
import esES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider value={{ apolloClient: client }}>
        <ConfigProvider locale={esES}>
          <App />
        </ConfigProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);