import styled from 'styled-components';
import { Theme } from '../../genericComponents/globalStyles';




const ButtonGeneral = styled.button`
  border:none ;
  padding: 5px 15px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
  
  label{
    margin-left: 5px; 
    cursor: pointer;
  }
`;

export const ButtonEnable = styled(ButtonGeneral)`
  background-color: ${Theme.color.acept};
`;

export const ButtonDisable = styled(ButtonGeneral)`
  background-color: ${Theme.color.cancel};
`;

export const Botonera = styled.div`
  display: Flex;
  justify-content:space-evenly;
  align-items:center;

`;

export const ButtonAceptar = styled(ButtonGeneral)`
  padding: 0px 50px;
  border-radius: 50px;
  height:35px;
  background-color: ${Theme.color.acept};
`;
export const ButtonCancelar = styled(ButtonGeneral)`
  padding: 0px 50px;
  border-radius: 50px;
  height:35px;
  background-color: ${Theme.color.cancel};
`;


