import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';


export default function CusttomModal(props){
  return(
    <>
      <ContentModal {...props}></ContentModal>
    </>

  )
}


const ContentModal = styled(Modal)`
  margin-top: ${(props)=> props.marginTop || '0'};
  text-align: ${(props)=> props.textAlign || 'start'};
  .ant-modal-content, 
  .ant-modal-header,
  .ant-modal-title{
    font-size: 1.5rem;
    border-radius: 10px;
  }
  max-height: ${({height})=> height || 'auto'}


`;
