import React, { useState, useContext } from 'react';
import { Col, Row, Input, notification } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../genericComponents/globalStyles';
import Button from '../../genericComponents/Button';
import 'firebase/auth';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { loginSchema } from '../../../helpers/validationSchema';
import WarningFomr from '../../genericComponents/WarningForm';
import { FormAuth, TextInputTitle, TextTitle } from './generyStyled';
import { Context } from '../../../globalContext/globalContext';
import {  USERS } from '../../../helpers/graphqlQuerys';


export default function SingIn() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {apolloClient} = useContext(Context);
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validationSchema: loginSchema
  });

  function onSubmit(valores) {
    const { email, password } = valores
    setLoading(true);
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((r) => {
        const {user} = r;
        apolloClient.query({query: USERS ,  variables:{users_id1: user.uid} })
        .then((result)=>{
          const {role} = result.data.users_by_pk;
          
          if(role === "admin"){
            window.location.replace('/admin/stores');
          }else if (role === "partner" ){
            window.location.replace('/inventory');
          }else{
            window.location.replace('/inventory');
          }
        })
        .catch((errors)=>{ console.log("error" , errors)})
      })
      .catch((e) => {
        setLoading(false);
        if (e.code === 'auth/user-not-found') {
          notification.error({
            message: 'Usuario no registrado'
          });
        } else if (e.code === 'auth/wrong-password') {
          notification.error({
            message: 'Usuario y Constrseña no coiciden'
          });
        } else {
          notification.error({
            message: 'Ha ocurrido un error inesperado, por favor intente de nuevo'
          });
        }
      })
  }
  return (
    <>
      <Row justify="center">
        <Col xs={22} sm={18} md={12} ld={12} xl={10}>
          <FormAuth onSubmit={handleSubmit}>
            <Row gutter={[0, 0]}>
              <Col>
                <TextTitle color={Theme.color.primary}> Iniciar Sesión</TextTitle>
              </Col>
            </Row><br /><br />

            <Row gutter={[0, 20]} justify="center">
              <Col xs={22} md={18}>
                <TextInputTitle>Correo Electrónico</TextInputTitle>
                <Input
                  required
                  placeholder="example@gmail.com"
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  onBlur={() => setFieldTouched('email', true)}
                />
                {errors.email && touched.email && (<WarningFomr>{errors?.email}</WarningFomr>)}
              </Col><br />
              <Col xs={22} md={18}>
                <TextInputTitle>Contraseña</TextInputTitle>
                <Input.Password
                  required
                  placeholder="***********"
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  onBlur={() => setFieldTouched('password', true)}
                />
                {errors.password && touched.password && (<WarningFomr>{errors?.password}</WarningFomr>)}
              </Col>
            </Row><br/>
            <Row gutter={[0, 4]} justify="center">
              <Col xs={22} sm={20} md={14} >
                <Button
                  loading={loading ? true : undefined}
                  type='submint'
                  backgroundColor={Theme.color.acept}
                  color={'#fff'}
                >Iniciar</Button>
              </Col>
              <Col md={12}>
                <BtnResetPass
                  color={Theme.color.primary}
                  onClick={() => history.push('/reset/password')}
                >¿Has olvidado tu contraseña?
                </BtnResetPass>
              </Col>
            </Row><br />
          </FormAuth>
        </Col>
      </Row>
    </>
  )
}

const BtnResetPass = styled(Button)`
  background-color: #fff;
  label:hover{
    border-bottom: 1px solid ${Theme.color.primary}
  }
`;
