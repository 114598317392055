import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { CaretDownOutlined } from '@ant-design/icons';
import { Theme } from '../../../../genericComponents/globalStyles';
import Button from '../../../../genericComponents/Button';


const SelectColor = ({
  hexaColor,
  setHexaColor
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  
  const selectColorNew = (color) => {
    setHexaColor(color.hex)
  }
  
  return (
    <>
      <ContainerSelectColor>
        <TitleSelectColor>
          Color de la Categoría
        </TitleSelectColor>
        <SectionSelectColor> 
          {
            displayColorPicker ? (
              <>
                <SketchPicker
                  disableAlpha={true}
                  color={hexaColor}
                  onChangeComplete={selectColorNew}
                />
                <ButtonSave
                  widthHover={'218px'}
                  onClick={() => (
                    setDisplayColorPicker(false)
                  )}
                >Seleccionar color</ButtonSave>
              </>
            ) : (
                <>
                  <ButtonDisplayPicker
                    onClick={() => (
                      setDisplayColorPicker(true)
                    )}
                  >
                    <Icon><CaretDownOutlined /></Icon>
                    <Color backgroundColor={hexaColor} />
                  </ButtonDisplayPicker>
                </>
              )

          }
        </SectionSelectColor>
      </ContainerSelectColor>
    </>
  )
}


export default SelectColor


const ContainerSelectColor = styled.div`
  flex:1;
  margin: 0 10px;
  max-height: 20vh;
  padding-bottom: 10px;
  border: solid 1px #d9d9d9 ;
  padding: 5px;
`;
const TitleSelectColor = styled.label`
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  color:#ccc;

`;
const SectionSelectColor = styled.div`
  max-height: 5vh;
`;
const ButtonSave = styled(Button)`
  width: 220px;
  background-color: ${Theme.color.acept};
  padding: 1px 10px;
  color: #fff;
  font-size: 1em;

`;

const ButtonDisplayPicker = styled.div`
  width: 98%;
  height: 4vh;
  display: flex;
  justify-content: start;
  margin-top: 5px;
`;
const Color = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  background-color: ${(props) => props.backgroundColor ||  Theme.color.primary};
  border: 1px solid #ddd;
  flex: 1;
  height: 100%;
`;
const Icon = styled.div`
  background-color: #fff;
  font-size: 1em;

`;