import React, { useContext, useState } from 'react';
import HeaderAuth from '../../genericComponents/HeaderAuth';
import { Theme } from '../../genericComponents/globalStyles';
import Button from '../../genericComponents/Button';
import 'firebase/auth';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import WarningFomr from '../../genericComponents/WarningForm';
import { changePassword } from '../../../helpers/validationSchema';
import { Col, Row, Input, notification } from 'antd';
import { FormAuth, TextInputTitle, TextTitle } from './generyStyled';
import { Context } from '../../../globalContext/globalContext';


export default function ChangePassword(){
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {state:{user}} = useContext(Context);
  const {role:roleUser} = user;
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      password: '',
      passwordNew: '',
      passwordConfirm: ''
    },
    onSubmit,
    validationSchema: changePassword
  });

  
  function onSubmit(data){
    const user = firebase.auth().currentUser;
    const {email} = user;
    const {
      password,
      passwordNew,
      passwordConfirm,
    
    } = data;
    setLoading(true);
    firebase.auth()
    .signInWithEmailAndPassword(email , password)
    .then((r) => {
      setLoading(false);
      if(passwordNew === passwordConfirm){
        user.updatePassword(passwordNew)
        .then(function(r) {
          roleUser === "admin" ? window.location.replace('/admin/stores') : window.location.replace('/inventory');
          
          notification.success({
            message: 'Contraseña Actualizada',
          })
        })
        .catch(function(e) {
          console.log("error", e)
        });
      }else{
        notification.error({
          message: 'Contraseña no coiciden'
        });
      }
      
    })
    .catch((e)=> {
      setLoading(false);
      console.log("error", e)
      if (e.code === 'auth/wrong-password') {
        notification.error({
          message: 'Contraseña actual incorrecta'
        });
      } else {
        notification.error({
          message: 'Ha ocurrido un error inesperado, por favor intente de nuevo'
        });
      }
    })

    
    
  }


  return(
    <>
      <HeaderAuth height={'30vh'}/>
      <Row justify="center">
        <Col xs={24} sm={18} md={12} ld={12} xl={10}>
          <FormAuth onSubmit={handleSubmit}>
            <Row gutter={[0, 20]}>
              <Col>
                <TextTitle color={Theme.color.primary}>Cambiar Contraseña</TextTitle>
              </Col>
            </Row>

            <Row gutter={[0, 20]} justify="center">
              <Col xs={22} md={18}>
                <TextInputTitle>Contraseña Actual</TextInputTitle>
                <Input.Password
                  required
                  placeholder="Contraseña Actual"
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  onBlur={() => setFieldTouched('password', true)}
                />
                {errors.password && touched.password && (<WarningFomr>{errors?.password}</WarningFomr>)}
              </Col><br />
              <Col xs={22} md={18}>
                <TextInputTitle>Nueva contraseña</TextInputTitle>
                <Input.Password
                  required
                  placeholder="Nueva contraseña"
                  value={values.passwordNew}
                  onChange={(e) => setFieldValue('passwordNew', e.target.value)}
                  onBlur={() => setFieldTouched('passwordNew', true)}
                />
                {errors.passwordNew && touched.passwordNew && (<WarningFomr>{errors?.passwordNew}</WarningFomr>)}
              </Col><br />

              <Col xs={22} md={18}>
                <TextInputTitle>Confirmar contraseña</TextInputTitle>
                <Input.Password
                  required
                  placeholder="Confirmar contraseña"
                  value={values.passwordConfirm}
                  onChange={(e) => setFieldValue('passwordConfirm', e.target.value)}
                  onBlur={() => setFieldTouched('passwordConfirm', true)}
                />
                {errors.passwordConfirm && touched.passwordConfirm && (<WarningFomr>{errors?.passwordConfirm}</WarningFomr>)}
              </Col>
            </Row><br/>


            <Row gutter={[10, 4]} justify="center">
              <Col xs={20} sm={16} md={12} >
                <Button
                  loading={loading ? true : undefined}
                  type='submint'
                  backgroundColor={Theme.color.acept}
                  color={'#fff'}
                >Guardar</Button>
              </Col>
              <Col xs={20} sm={16} md={12} >
                <Button
                  backgroundColor={Theme.color.cancel}
                  color={'#fff'}
                  onClick={()=> roleUser === "admin" ? history.replace('/admin/stores') : history.replace('/inventory') }
                >Cancelar</Button>
              </Col>
            </Row>
          </FormAuth>
        </Col>
      </Row>
    </>
  )
}
