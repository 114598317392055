import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Input } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import Button from '../../genericComponents/Button';
import CusttomModal from '../../genericComponents/CusttomModal';

export default function AddSubcategory({
  isModalVisible,
  handleCancel,
  dataToEdit,
  handleOkSubCategory,
}) {

  const [nameSubCategory, setNameSubCategory] = useState("");
  

  useEffect(() => {
    if(isModalVisible){
      if(dataToEdit?.title){
        setNameSubCategory(dataToEdit?.title)
      }else{
        setNameSubCategory("");
      }
    }
  
  },[isModalVisible , dataToEdit?.title ])

  

  return (
    <>

      <CusttomModal
        title="SubCategoria"
        visible={isModalVisible}
        footer={null}
        marginTop={'100px'}
        onCancel={handleCancel}
        
      >
        <Row justify="start" >
          <Col span={16} >
            <Input
              placeholder="Nueva Subcategoria"
              value={nameSubCategory}
              onChange={(e)=> setNameSubCategory(e.target.value)}
              
            />
          </Col>
        </Row> <br />
        <Row justify="end">
          <Col span={8}>
            <SectionButton>
              <CusttomButton
                backgroundColor={Theme.color.acept}
                color={"#fff"}
                onClick={()=>(handleOkSubCategory(nameSubCategory ,  dataToEdit.subCategorieId , dataToEdit.categoriesId  ))}
                //onClick={()=>console.log('ddd')}
              > <CustomLabel>Guardar</CustomLabel>
            </CusttomButton>
            </SectionButton>
          </Col>
        </Row>

      </CusttomModal>
    </>
  )
}








const SectionButton = styled.div`
 
`;
const CusttomButton = styled.div`
  padding: 2px 10px;
  width: ${({width}) => width || '100%'};
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  flex: ${({flex}) => flex || '1'};
  text-align: center;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props)=> props.boxShadow || 'none'};
  transition-duration: 0.4s;
  :hover{
    background-color: ${(props)=> props.hover};
    width: ${({widthHover}) => widthHover || '98%'};
  }
`;
const CustomLabel = styled.label`
  margin:auto;
  cursor: pointer;
  text-align: center;
  font-size: 1.5em;
  color: ${(props) => props.color || '#fff'};

`;