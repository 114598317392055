import React, { useContext} from 'react';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Theme } from '../../genericComponents/globalStyles';
import styled from 'styled-components';
import { Col, message, Row } from 'antd';
import TableInvetory from '../components/TableInventory';
import { useMutation, useQuery } from '@apollo/client';
import { PRODUCTS  } from '../../../helpers/graphqlQuerys';
import { Context } from '../../../globalContext/globalContext';
import { DELETE_PRODUCT } from '../../../helpers/graphqlMutation';
import FilterOption from '../components/FilterOption';


export default function InvetoryScreen() {
  const {state:{user}} = useContext(Context);
  const {data,loading,refetch} = useQuery(PRODUCTS,{variables:{email:user.email}});
  const stateLoading = loading;
  const [deleteProduct] = useMutation(
    DELETE_PRODUCT, { onCompleted, onError } 
  );

  function onCompleted(result){
    refetch();
    message.success("Producto eliminado")
  }

  function  onError(result){
    console.log(result);
  }
  

  return (
    <HeaderMenu backgroudColorI={Theme.color.primary} colorI={'#fff'}>
      <Containerinventory>
        <Row justify="center" >
          <Col xs={22} sm={20} md={24} >

            <Row justify="center" >
              <Col xs={24} sm={22}  lg={22}>
                <FilterOption/>
              </Col>
            </Row><br/>


            <Row justify="center" >
              <Col xs={24} sm={22}  lg={22}>
                <TableInvetory 
                  deleteProduct={deleteProduct} 
                  stateLoading={stateLoading} 
                  key={data?.products.products_id} 
                  products={data?.products || []}
                  />
              </Col>
            </Row>
          

          </Col>
        </Row>
      </Containerinventory>

    </HeaderMenu>

  );
}

const Containerinventory = styled.div`
  margin: auto;
  width: 100%;


`;

