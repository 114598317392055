import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  password: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),
  email: yup.string().email('Debe ingresar un correo valido').required('Este campo es requerido'),
  
});

export const resetPassSchema = yup.object().shape({
  email: yup.string().email('Debe ingresar un correo valido').required('Este campo es requerido'),
});

export const changePassword = yup.object().shape({
  password: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),
  passwordNew: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),
  passwordConfirm: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),
})

export const SignUpSchema = yup.object().shape({
  name: yup.string().required('Este campo es requerido').max(40,'El Nombre de la tienda debe tener maximo 40 caracteres'),
  email: yup.string().email('Debe ingresar un correo valido').required('Este campo es requerido'),
  direction: yup.string().required('Este campo es requerido'),
  password: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),
  passwordConfirm: yup.string().required('Este campo es requerido').min(6,'La contraseña debe tenere minimo 6 caracteres'),

});

export const StoreSchema = yup.object().shape({
  image_url: yup.string(),
  name: yup.string().required('Este campo es requerido').max(40,'El Nombre de la tienda debe tener maximo 40 caracteres'),
  description: yup.string().required('Este campo es requerido')
  .min(30, 'La descripción debe tener minimo 30 caracteres')
  .max(1200, 'La descripcion no debe sobre pasar los 1200 caracteres'),
  numberPhone: yup.number().typeError('Solo caracteres de tipo numericos').required('Este campo es requerido'),
  numberPhoneWs: yup.number('Solo caracteres de tipo numericos'),
  direction: yup.string().typeError().required('Este campo es requerido'),
  directionDetails: yup.string()
});

export const ProductSchema = yup.object().shape({
  name: yup.string().required('Este campo es requerido').max(60,'El Nombre de la tienda debe tener maximo 60 caracteres'),
  description: yup.string().required('Este campo es requerido')
  .min(30, 'La descripción debe tener minimo 30 caracteres')
  .max(1200, 'La descripcion no debe sobre pasar los 1200 caracteres'),
  price: yup.number().typeError('Solo caracteres de tipo numericos').required('Este campo es requerido'),

  categorySelected: yup.string().required('Debe seleccionar una categoria'),

})
