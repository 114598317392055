// import { auth } from '../helpers/firebase.module';

export const reducer = (state) => {
  return {
    //Only curry functions:::::
    setUser: (data) => ({ user: data }),
    set: (key, value) => ({ [key]: value }),
    setCategories:(data) => ({categories:data}),
    setStore: (data)=>({store: data}),
    setSessionToken: (data)=>({store: data})
  };
};

export const initialState = () => {
  return {
    user: {},
    categories:[],
    store: {},
    sessionToken: [],
  };
};
