import React from 'react';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Theme } from '../../genericComponents/globalStyles';
import PagConstrucion from '../../genericComponents/PagConstruccion';



export default function HomeScreen(){
  return(
    <HeaderMenu backgroudColorH={Theme.color.primary} colorH={'#fff'} >
      <PagConstrucion/>
    </HeaderMenu>

  )
}