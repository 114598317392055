import firebase from 'firebase/app';
import { AUTOCOMPLETEDIRECTION , DIRECTIONDETAILS } from './graphqlQuerys';

export async  function autocompletadoMaps(direction, sessionToken, apolloClient) {
  try{
    const result = await apolloClient
    .query({
      query: AUTOCOMPLETEDIRECTION,
      variables: {
        direction: direction,
        sessionToken : sessionToken
      },
    })

    const prediction =  result?.data?.autocompleteDirections;
      return("helperFuntion",prediction);
  } 
  catch (e) { 
    console.log(e);
  } 

}

export async function getDetailsAddress(placeID , sessionToken, apolloClient){
  try{
    const result = await apolloClient
    .query({
      query: DIRECTIONDETAILS ,
      variables: {
        placeID: placeID,
        sessionToken : sessionToken
      },
    })

    const detailsAddress = result?.data.directionDetails.location;
    return(detailsAddress)
  }

  catch (e){
    console.log(e);
  }
}


export async function UploadFirebaseImage(e, ref) {
    try {
      const file = e[0];

      await firebase.storage().ref(ref).put(file);
      const url = await firebase.storage().ref(ref).getDownloadURL();
      return (url);

    } catch (error) {
      return (null);
    }
  }