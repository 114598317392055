import styled from 'styled-components';
import { Input,TimePicker } from 'antd';
import {Text} from '../../genericComponents/Text';
import { Theme } from '../../genericComponents/globalStyles';
export const ContainerUpBtn= styled.div`
  width: 100%;
  height: 15vh;
  background-color: #f1efef;
  opacity: 0.44;

`;

export const Card = styled.div`
  background-color: #fff;
  margin: 20px 10px ;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);

`;

export const ContainerInput = styled.div`
  background-color: #fbfbfb;
  width:95%;
  margin:auto;
`; 

export const TextTitle = styled(Text)`
  font-size: 2em;
  font-weight: 500;
`;

export const TextSubTitle = styled(Text)`
  font-size: 1.6em;
  margin-left: 5px;
  margin-bottom: 10px;

`;


export const TextInputTitle = styled(Text)`
  font-size: 1.3em;
  margin:15px;
`;

export const R = styled.label`
  color:${Theme.color.primary};
`;

export const CustomTextArea = styled(Input.TextArea)`
`;

export const ContainerMaps= styled.div`
  width: 100%;
  height: 35vh;

`;

export const TimePickerC = styled(TimePicker)`
  width: 100%;

`;

export const Table = styled.table`&&&{
  width: 100%;
  text-align: center;
  font-size: 1.3em;
  font-weight: normal;
  background-color: #fff;
  border-radius: 10px;
  border: solid 1px #eee;}
`

export const TableTh = styled.th`
  padding: 10px;  
  background-color: #eee;
  font-weight: normal;

`

export const TableTd = styled.td`
  padding: 10px;
  border-bottom: solid 1px #eee;
  
`

