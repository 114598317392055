import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Button from '../../genericComponents/Button';
import { Theme } from '../../genericComponents/globalStyles';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../helpers/routes';

//const {Option} = Select


export default function FilterOption() {
  const history = useHistory();
  
  return (
    <Container>
      <Row justify="end"  >
        {/* <Col xs={10} sm={6} md={4} lg={3} xl={2} >
          <Container>
            <TextCategori>Ordenar por</TextCategori>
          </Container>
        </Col >
        <Col xs={14} sm={8} md={6} lg={6} xl={4}>
          <SelectCusttom  value='Aa - Zz'   >
            <Option>Aa - Zz </Option>
          </SelectCusttom>
        </Col> */}
        <Col xs={10} sm={10} md={8} lg={4} xl={4}>
          <Container>
            <Button
              backgroundColor={Theme.color.acept }
              color={'#fff'}
              hover={Theme.color.aceptHover}
              onClick={()=> history.push(routes.PRODUCT_CREATE)}
            >Agregar producto
            </Button>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

// const SelectCusttom = styled(Select)`
//   width: 100%;
// `;

// const TextCategori = styled(Text)`
//   margin: auto;
//   font-size: 1.5em;
//   text-align:center;
// `;

const Container = styled.div`
  min-width: 100%;
`;

