import styled from 'styled-components';
import { Input} from 'antd';
import {Text} from '../../genericComponents/Text';


export const ContainerInput = styled.div`
  background-color: #fbfbfb;
  margin: 0 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);

`;

export const TextSubTitle = styled(Text)`
  font-size: 1.6em;
  margin-left: 5px;

`;

export const TextInputTitle = styled(Text)`
  font-size: 1.3em;
  margin:15px;
`;

export const CustomTextArea = styled(Input.TextArea)`
  max-height: 200px;
`;

export const ContainerImg = styled.div` 
  background-color:#fbfbfb;
  width: 100%;
  height: 55vh;
  margin: auto;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);


`;

export const Botones = styled.div`
  margin: 30px auto;
`;



