import React, { useState } from 'react';
import {  ButtonDisable, 
          Botonera,
          ButtonAceptar,
          ButtonCancelar,
} from './generyStyled'
import { CloseCircleOutlined } from '@ant-design/icons';
import CusttomModal from '../../genericComponents/CusttomModal';



export default function Disable({
  onClick
}) {




  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };



  const handleOk = () => {
    onClick()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <ButtonDisable onClick={showModal}>
        <CloseCircleOutlined />
        <label>Inabilitar</label>
      </ButtonDisable>
      <CusttomModal
        title="¿Seguro que deseas inhabilitar esta tienda?"
        visible={isModalVisible}
        footer={null}
        textAlign={'center'}
        marginTop={'150px'}
        onCancel={handleCancel}
      >
        <Botonera>
          <ButtonAceptar
            type='submint'
            onClick={handleOk}
          ><label>Aceptar</label>
          </ButtonAceptar>
          <ButtonCancelar
            onClick={handleCancel}
          >Cancelar
          </ButtonCancelar>
        </Botonera>
      </CusttomModal>
    </>
  )
}

