import React, { useState }  from 'react';
import {  ButtonEnable, 
          Botonera,
          ButtonAceptar,
          ButtonCancelar,
} from './generyStyled';
import { CheckCircleOutlined } from '@ant-design/icons';
import CusttomModal from '../../genericComponents/CusttomModal';


export default function Enable({
  onClick
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };



  const handleOk = () => {
    onClick()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <ButtonEnable onClick={showModal}>
        <CheckCircleOutlined />
        <label>Habilitar</label>
      </ButtonEnable>
      <CusttomModal
        title="¿Seguro que deseas habilitar esta tienda?"
        visible={isModalVisible}
        footer={null}
        textAlign={'center'}
        marginTop={'150px'}
        onCancel={handleCancel}
      >
        <Botonera>
          <ButtonAceptar
            onClick={handleOk}
          ><label>Aceptar</label>
          </ButtonAceptar>
          <ButtonCancelar
            onClick={handleCancel}
          >Cancelar
          </ButtonCancelar>
        </Botonera>
      </CusttomModal>

    </>
  )
}