import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';

  const link = new HttpLink({
    uri: `https://${process.env.REACT_APP_GRAPHQL_URI}`,
    headers: {
      'content-type': 'application/json',
      'x-hasura-admin-secret': 'test'
    },
  });
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions:{
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
  export {client};


