import React from 'react'
import { Row, Col, Input } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import {
  Card,
  TextSubTitle,
  TextInputTitle,
  ContainerInput,
  R,
} from './generiStyled';
import WarningFomr from '../../genericComponents/WarningForm';


export default function StoreContacts({ 
  numberPhone, 
  numberPhoneWs,
  webSite,
  instagramUser,
  setFieldValue, 
  setFieldTouched, 
  touched, 
  errors 
}) {
  return (
    <Card>
      <Row gutter={[8, 16]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary}>Contacto</TextSubTitle>
        </Col>
        <ContainerInput>
          <Row gutter={[16,16]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <TextInputTitle>Nro Telefóno <R>*</R> </TextInputTitle>
              <Input
                placeholder="123 266 51 21"
                required
                value={numberPhone}
                onChange={(e) => setFieldValue('numberPhone', e.target.value)}
                onBlur={() => setFieldTouched('numberPhone', true)}
              />
              {errors.numberPhone && touched.numberPhone && (<WarningFomr>{errors.numberPhone} </WarningFomr>)}
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
              <TextInputTitle>WhatsApp (Opcional)</TextInputTitle>
              <Input
                placeholder="123 328 12 12"
                value={numberPhoneWs}
                onChange={(e) => setFieldValue('numberPhoneWs', e.target.value)}
                onBlur={() => setFieldTouched('numberPhoneWs', true)}
              />
              {errors.numberPhoneWs && touched.numberPhoneWs && (<WarningFomr>{errors.numberPhoneWs} </WarningFomr>)}
            </Col>
            <Col xs={22} md={12}>
              <TextInputTitle>WebSite (Opcional)</TextInputTitle>
              <Input
                placeholder="https://www.ejemplo.com/"
                value={webSite}
                onChange={(e) => setFieldValue('webSite', e.target.value)}
                onBlur={() => setFieldTouched('webSite', true)}
              />
              {errors.webSite && touched.webSite && (<WarningFomr>{errors.webSite} </WarningFomr>)}
            </Col>
            <Col xs={22} md={12}>
              <TextInputTitle>Intagram (Opcional)</TextInputTitle>
              <Input
                placeholder="@UsuarioEjemplo"
                value={instagramUser}
                onChange={(e) => setFieldValue('instagramUser', e.target.value)}
                onBlur={() => setFieldTouched('instagramUser', true)}
              />
              {errors.instagramUser && touched.instagramUser && (<WarningFomr>{errors.instagramUser} </WarningFomr>)}
            </Col>
          </Row>
        </ContainerInput>
      </Row>
    </Card>
  );
}