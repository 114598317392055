import React from 'react';
import styled from 'styled-components';
import imgPaguina404 from '../../assets/img/paguinaNoEncontrada.svg';

export default function Paguina404(){
  return(
    <>
      <Container>
        <Img src={imgPaguina404}/>
      </Container>
    </>
  )
}


const Img = styled.img`
  margin-top: 30px;
`


const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  

`
