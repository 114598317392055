import React, { useContext, useEffect, useState} from 'react';
import { Col,Row, Select } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import {
  ContainerInput,
  TextSubTitle,
  TextInputTitle,
} from './generiStyled'; 
import WarningFomr from '../../genericComponents/WarningForm'
import { Context } from '../../../globalContext/globalContext';
import styled from 'styled-components';

export default function CategoryProduct({
    categorySelected ,
    listCategories, 
    setFieldValue, 
    loading,
    subcategorySelectd,
    setFieldTouched,
    touched,
    errors,
  
  }){
  const {state:{categories}} = useContext(Context);
  const [subCategories, setSubCategories] = useState([])

  useEffect(() => {
    if(categorySelected){
      categories?.map((cat) => ( cat.categories_id  === categorySelected && (setSubCategories(cat?.sub_categories))) )
    }
  }, [categories, categorySelected])
  

  function getSubcategory(categoryId){
    categories?.map((cat)=> ( cat.categories_id === categoryId &&  (setSubCategories(cat?.sub_categories)) ))
  }


  return(
    <ContainerInput>
      <Row gutter={[8, 16]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary}>Categoria</TextSubTitle>
        </Col>
        <Col xs={22} md={22} xl={20}>
          <TextInputTitle>Categorias*</TextInputTitle>
          <SelectCategories
            required
            value={categorySelected} 
            onChange={(e)=>{
              setFieldValue('categorySelected',e)
              getSubcategory(e)
            } }
            onBlur={()=> setFieldTouched('categorySelected', true)}
            >
            {categories?.map((category) => (
              
              <Select.Option 
                key={ category.categories_id} 
                value={category.categories_id}
              >
                {category.title}
              </Select.Option>
              ))
            }

          </SelectCategories>

          {
              errors.categorySelected && touched.categorySelected &&  (<WarningFomr >{errors.categorySelected}</WarningFomr>)
            }
          
        </Col>
        <Col xs={22} md={22} xl={20}>
          <TextInputTitle>SubCategoria*</TextInputTitle>
          <SelectCategories
            required
            value={subcategorySelectd} 
            onChange={(e)=>setFieldValue('subcategorySelectd',e)} 
            onBlur={()=> setFieldTouched('subcategorySelectd', true)}
            >
            {
              subCategories?.map((subCategory) => (
                <Select.Option 
                  key={ subCategory?.sub_categories_id} 
                  value={subCategory?.sub_categories_id}
                >
                  {subCategory.title}
                </Select.Option>
              ))
            }

            
          </SelectCategories>
        </Col>

        
      </Row>
    </ContainerInput>
  )
}

const SelectCategories = styled(Select)`
  width: 100%;
`;