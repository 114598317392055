import React from 'react';
import styled from 'styled-components';
import banner from '../../assets/img/BannerHeaderAuth.png'
import Logo from '../../assets/img/LogoAuth.png'

export default function HeaderHeaderAuth(props){
  return(
    <>
      <ContainerHeaderAuth {...props}>
          <LogoImg/>
      </ContainerHeaderAuth>
    </>
  )
}

const ContainerHeaderAuth = styled.div `
  width: 100%;
  height: ${(props)=> props.height || '25vh'};
  margin:0;
  background-image: url(${banner});
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover ;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const LogoImg = styled.div`
  width: 120px;
  height:120px;
  z-index:1;
  position: absolute;
  background-image: url(${Logo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;