import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Theme } from '../genericComponents/globalStyles';

export default function CusttomSpin(props){
  return(
    <ContainerSpin {...props}>
      <SpinC  size="large" tip="Cargando..."/>
    </ContainerSpin>
  )
}

const ContainerSpin = styled.div`
  display: flex;
  width: 50%;
  height: ${(props) => props.heigthSpin || '100vh'};
  margin: auto;
  justify-content:center;
  align-items: center;
`;

const SpinC = styled(Spin)`&&&{
  color: ${Theme.color.sencondary};
  font-weight: 500;
  
  .ant-spin-dot-item {
    background-color: ${Theme.color.primary};
  }
}
`;