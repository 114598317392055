import React, { useState } from 'react'
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Theme } from '../../../../genericComponents/globalStyles';
import { UploadFirebaseImage } from '../../../../../helpers/helperFuntion';
import { Spin } from 'antd';


const ImageCategorie = ({
  imgUrl,
  setImgUrl,
  idCategory,
  nameCategory
}) => {


  const [loadingImg, setLoadingImg] = useState(false)

  async function uplopadingImg(img) {
    setLoadingImg(true);
    const url = (await UploadFirebaseImage(
      img.target.files, `/categories/${nameCategory}/${idCategory}/${(new Date()).getTime()}${img.target.files[0].name}`
    ))
    setImgUrl(url)

    setLoadingImg(false);


    
  }


  return (
    <Container >
      {
        loadingImg ? (
          <><SpinLoading/></>
        ) : (
          <Imagen
            backgroundImg={imgUrl}
            colorIcon={`${imgUrl ? '#ccc' : '#ccc'}`}
            colorIconHover={`${imgUrl ? '#fff' : Theme.color.primary}`}
          >
            { 
              imgUrl?(
                <Title>Cambiar imagen</Title>
              ):(
                <Title>Agregar una imagen</Title>
              )
            }

            <ImgOptionUpload  >
              <UploadButton
                id="img"
                type="file"
                placeholder="+ Subir Imagen"
                multiple
                onChange={uplopadingImg}
              />
              <UploadLabel for="img">
                <PlusCircleOutlined />
              </UploadLabel>

            </ImgOptionUpload>
          </Imagen>
        )
      }
    </Container >
  )
}


export default ImageCategorie

const Container = styled.div`
  border: solid 1px #ccc;
  border-radius: 20px;
  width: 90%;
  height: 21vh;
  padding: 1px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  


`

const Imagen = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${({ backgroundImg }) => backgroundImg || 'none'});
  background-position: center;
  background-size: cover;
  cursor:pointer;
  color: ${({ colorIcon }) => colorIcon || '#000'};

  :hover{
    opacity: 0.9;
    color: ${({ colorIconHover }) => colorIconHover || Theme.color.primary};
    
  }

`

const ImgOptionUpload = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 3em;
  font-weight: 200px;
  padding: 20px;
  margin: 5px 0;
  
  z-index: 1000;

  label{
    font-size: 1em;
  }

`;

const UploadButton = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  
`;

const Title = styled.label`
  font-size: 15px;
  font-weight: 400;
  text-align: center;


`

const SpinLoading = styled(Spin) `&&&{
  color: ${Theme.color.sencondary};
  font-weight: 500;
  
  .ant-spin-dot-item {
    background-color: ${Theme.color.primary};
  }
}
 

`