export const routes = {
  PAGE_NOT_FOUND: "*",
  HOME: "/",
  AUTH: "/auth",
  STORE: "/store",
  INVENTORY: "/inventory",
  SETTING: "/setting",
  PRODUCT_CREATE: "/product/create",
  PRODUCT_EDIT: "/product/edit/:id",
  RESET_PASSWORD: "/reset/password",
  CHANGE_PASSWORD: "/change/password",
  ADMIN_STORES: "/admin/stores",
  ADMIN_CATEGORIES: "/admin/categories",

}