import { gql } from "@apollo/client";

export const CREATE_STORE_ACCOUNT = gql`
  mutation(
    $name:String!, 
    $password: String!,
    $direction: String!, 
    $email: String!, 
    $latitude: Float!,
    $longitude:Float!, 
    ) {
      createStoreAccount(
          direction: $direction, 
          email: $email, 
          latitude: $latitude, 
          longitude: $longitude, 
          name: $name, 
          password: $password)
      }
  
`
export const UPDATE_STORE = gql`
  mutation (
    $stores_id: uuid!,
    $name:String!, 
    $direction: String!, 
    $latitude: numeric!,
    $longitude: numeric!,
    $closeTime: String!,
    $directionDetails: String,
    $imageUrl: String!,
    $instagramUser: String!,
    $openTime: String!,
    $phoneNumber: String!,
    $webSite: String!,
    $phoneNumberWs: String!,
    $description: String!,
    $schedule: jsonb
    ){ 
      update_stores_by_pk(pk_columns: {stores_id: $stores_id}, _set: {
        phone_number: $phoneNumber, 
        website: $webSite, 
        whatsapp_phone: $phoneNumberWs, 
        name: $name, 
        open_time: $openTime, 
        longitude: $latitude, 
        latitude: $longitude, 
        instagram_user: $instagramUser, 
        image_url: $imageUrl, 
        direction_details: $directionDetails, 
        direction: $direction, 
        description: $description, 
        close_time: $closeTime,
        schedule: $schedule
        }){
          stores_id
          name
          longitude
          latitude
          instagram_user
          direction_details
          direction
          description
          close_time
          open_time
          phone_number
          website
          whatsapp_phone
          image_url
          schedule
        }
  }
`

export const CHANGE_STORES_STATUS = gql `
  mutation(
    $stores_id: uuid!, 
    $is_active: Boolean!){
    update_stores(where: {stores_id: {_eq: $stores_id}}, _set: {
      is_active: $is_active
      }) {
      returning {
        is_active
      }
    }
  }

`
export const CREATE_PRODUCT = gql`
  mutation(
    $description: String!,
    $name: String!,
    $price: numeric!,
    $category: uuid!,
    $storeId: uuid!, 
    $images_list: jsonb!,
    $sub_category: uuid!,
  ) {
      insert_products(objects: {
      description: $description, 
      name: $name, 
      price: $price, 
      main_category:$category, 
      stores_id: $storeId,
      images_list: $images_list,
      sub_category: $sub_category,
      }) {
      affected_rows
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation (
    $description: String!,
    $name: String!,
    $price: numeric!,
    $product_id: uuid!,
    $category: uuid!,
    $images_list: jsonb!,
    $sub_category: uuid!,
    ){ 
    update_products_by_pk(pk_columns: 
      {products_id: $product_id}, _set: 
        {
          description: $description, 
          price: $price, 
          name: $name, 
          main_category: $category,
          images_list: $images_list
          sub_category: $sub_category
        }) {
      stores_id
    }
  }
`
export const DELETE_PRODUCT = gql`
  mutation (
    $productId: uuid!,
    ){
    delete_products_by_pk(
      products_id: $productId
    ) {
      products_id
    }
  }

`

export const UPDATE_SUB_CATEGORY = gql`
  mutation (
    $title: String!,
    $sub_categories_id: uuid!
  ){
    update_sub_categories_by_pk(
      pk_columns: {sub_categories_id: $sub_categories_id}, 
      _set: {title: $title}
    ){
      title,
      sub_categories_id
    }
  }

`

export const CREATE_SUB_CATEGORY = gql`
  mutation (
    $categories_id: uuid!, 
    $title: String!
    ){
      insert_sub_categories(objects:{
        categories_id: $categories_id, 
        title: $title
      }) 
      {
        returning {
          title,
          sub_categories_id,
          categories_id
        }
      }
}


`
export const UPDATE_CATEGORY = gql `
  
  mutation (
    $title: String!, 
    $exa_color: String!,
    $image: String!,
 
    $categories_id: uuid!,
    ) {
      update_categories_by_pk(
        pk_columns: {categories_id: $categories_id}, 
        _set: {
          title: $title, 
          exa_color: $exa_color,
          image: $image,
         
        }) {
          exa_color
          image
          title
          categories_id
    }
  }
`

export const INSERT_CATEGORY = gql `
  mutation (
    $exa_color: String!, 
    $image: String!,
    $title: String!
    ){
    insert_categories(objects:{
      exa_color: $exa_color, 
      image: $image, 
      title: $title}
      ){
      returning {
        categories_id
        image
        exa_color
        title
      }
    }
  }
`