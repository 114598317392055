import { FileImageOutlined } from '@ant-design/icons';
import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import { UploadFirebaseImage } from '../../../helpers/helperFuntion';


export default function PhotoStore({
  imageUrl,
  setFieldValue,
  idStore
}) {

  const [image, setImage] = useState('');

  useEffect(() => {
    setImage(imageUrl);

  }, [imageUrl]);

  
  async function uploadingImages(e) {
    const newImg = ( await UploadFirebaseImage(
      e.target.files,`/tiendas/${idStore}/${(new Date()).getTime()}${e.target.files[0].name}` 
    ));

    setImage(newImg);

    setFieldValue('imageUrl', newImg);

   

    
  }
  
  return (
    <ContainerPhotoStore backgroundImage={image}>
      <PhotoStoreEdit>
        <UploadButton
          id="img"
          type="file"
          onChange={uploadingImages}
        />
        <UploadLabel for="img">
          <FileImageOutlined /> 
          Cambiar imagen de la tienda 
        </UploadLabel>
      </PhotoStoreEdit>


    </ContainerPhotoStore>
  )
}


const ContainerPhotoStore = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${(props => props.backgroundImage)});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

`;

const PhotoStoreEdit = styled.div`
  background-color: #0000;
  color:#0000;
  width: 100%; 
  height: 100%;
  :hover{
    background-color: #b7b7b7b8;
    color: #fff;
  }

`;

const UploadButton = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 3em;
`;