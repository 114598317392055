import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../genericComponents/globalStyles';
import Column from 'antd/lib/table/Column';
import Button from "../../genericComponents/Button";
import { useHistory } from 'react-router-dom';
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { Popconfirm, message } from 'antd';
import imgProduct from '../../../assets/img/bxsShopping.svg'
import CusttomSpin from '../../genericComponents/CusttomSpin';



export default function TableInvetory({
  products,
  stateLoading,
  deleteProduct,
}) {
  function confirm(id) {
    deleteProduct({
      variables: {
        productId: id
      }
    })
    message.loading('Eliminando...', 1);

  }

  function cancel(e) {
    console.log(e)
  }

  return (
    <>
      {
        !stateLoading ? (
          <>
            <TableCusttom
              rowKey={record => record.products_id}
              dataSource={products}
              pagination={{ pageSize: 30, position: ['bottomCenter'] }}
              scroll={{ y: 440, x: 900 }}
            >
              <Column
                title='Foto'
                dataIndex='images_list'
                key='images_list'
                width='120px'
                fixed='left'
                render={(photo) =>
                  <ContainerPhotoImg>
                    {
                      photo[0] ? (
                        <>
                          <PhotoStore urlImg={photo[0]} />
                        </>
                      ) : (
                        <PhotoStoreDeafult />
                      )
                    }
                  </ContainerPhotoImg>
                }
              />

              <Column
                title='Producto'
                dataIndex='name'
                key='name'
              />

              <Column
                title='Description'
                dataIndex='description'
                key='description'
                render={text => <LabelDescription>
                  {text?.length > 10 ? `${text.substring(0, 80)}...` : text}
                </LabelDescription>}
              />

              <Column
                title='Categoria'
                dataIndex='category'
                key='category'
                width='160px'
                render={(text) => <label>{text?.title}</label>}
              />

              <Column
                title='Subcategoria'
                dataIndex='category'
                key='category'
                width='160px'
                render={(text) => <label>{text?.sub_categories[0]?.title}</label>}
              />


              <Column
                title='Precio'
                dataIndex='price'
                key='price'
                width='100px'
                render={(text) => <LabelPrecio>{text} $</LabelPrecio>}
              />

              <Column
                width='70px'
                render={
                  (_, record) => (
                    <ContainerButton>
                      <Popconfirm
                        title="¿Estas seguro que desea eliminar este producto?"
                        onConfirm={() => confirm(record.products_id)}
                        onCancel={cancel}
                        okText="Si"
                        cancelText="No"
                      >
                        <ButttonAction>
                          <DeleteFilled />
                        </ButttonAction>
                      </Popconfirm>
                      <ButtonEdit id={record.products_id}  ></ButtonEdit>
                    </ContainerButton>
                  )
                }

              />
            </TableCusttom>
          </>
        ) : (
          <CusttomSpin heigthSpin={'40vh'} />
        )

      }
    </>
  )
}


const TableCusttom = styled(Table)`
  tr th, tr td{
    text-align: center;
    
  }

  tr th {
    color:#fff;
    background-color: ${Theme.color.primary};
    font-size: 1.3em;
    
  }

`;



function ButtonEdit({ id }) {
  const history = useHistory();
  return (
    <ButttonAction onClick={() => history.push(`/product/edit/${id}`)}><EditOutlined /></ButttonAction>
  )


}

const ContainerButton = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
`;


const ButttonAction = styled(Button)`
    background-color: #0000;
    color: #9e9e9e;
    cursor: pointer;
    
    :hover{
      color:#737070;
    }
  `;


const LabelDescription = styled.label`
  
`;

const LabelPrecio = styled.label`
  color: ${Theme.color.primary};


`;


const ContainerPhotoImg = styled.div`
  max-width: 100%;  
`;



const Images = styled.div`
  width: 70px;
  height: 70px;
  margin: auto;
  border: 1px solid #eee;
  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

const PhotoStoreDeafult = styled(Images)`
  background-image: url(${imgProduct});
`;

const PhotoStore = styled(Images)`
  background-image: url(${(props) => props.urlImg});
  

`;