import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Row, Col, message, Popconfirm } from 'antd';
import Button from '../../genericComponents/Button';
import { Theme } from '../../genericComponents/globalStyles';
import StoreData from '../components/StoreData';
import StoreContacts from '../components/StoreContacts';
import Schedules from '../components/Schedules';
import StoreDirection from '../components/StoreDirection';
import { useFormik } from 'formik';
import { StoreSchema } from '../../../helpers/validationSchema';
import { Context } from '../../../globalContext/globalContext';
import { useMutation } from '@apollo/client';
import { UPDATE_STORE } from '../../../helpers/graphqlMutation';
import { notification } from 'antd';
import PhotoStore from '../components/PhotoStore';
import { useHistory } from 'react-router-dom';

export default function StoreScreen() {
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      imageUrl: '',

      name: '',
      email: '',
      description: '',

      numberPhone: '',
      numberPhoneWs: '',
      webSite: '',
      instagramUser: '',

      horaAper: '',
      horaCier: '',
      diasLaborbles: '',


      direction: '',
      directionDetails: '',

    },
    onSubmit,
    validationSchema: StoreSchema
  });
  const history = useHistory();
  const { dispatch: { setStore }, state: { store } } = useContext(Context);
  const { apolloClient } = useContext(Context)

  useEffect(() => {
    setFieldValue('name', store?.name || '');
    setFieldValue('email', store?.user?.email || '');
    setFieldValue('description', store?.description || '');
    setFieldValue('numberPhone', store?.phone_number || '');
    setFieldValue('numberPhoneWs', store?.whatsapp_phone || '');
    setFieldValue('webSite', store?.website || '');
    setFieldValue('instagramUser', store?.instagram_user || '');
    setFieldValue('direction', store?.direction || '');
    setFieldValue('directionDetails', store?.direction_details || '');
    setFieldValue('longitude', store?.longitude || '');
    setFieldValue('latitude', store.latitude || '');
    setFieldValue('imageUrl', store?.image_url || '')
    setFieldValue('schedule', store?.schedule || {})

  }, [store , setFieldValue])


  const [updateStore] = useMutation(
    UPDATE_STORE, {
    onCompleted: onCompletedUpdate, onError: onErrorUpdate,
    variables: {
      stores_id: store.stores_id
    }
  } ,
  );


  function onCompletedUpdate(data) {
    notification.success({
      message: 'Guardado ',
      description: 'Se guardaron exitosamente los cambios en la informacion de la tienda'
    })
  
    setStore(data.update_stores_by_pk)

    // history.push('/inventory');

  }

  function onErrorUpdate(data) {
    console.log(data)
  }

  function onSubmit(value ) {
    updateStore({
      variables: {
        name: value.name,
        description: value.description,
        phoneNumber: value.numberPhone,
        webSite: value.webSite,
        phoneNumberWs: value.numberPhoneWs,
        openTime: "09:00am",
        longitude: value.longitude,
        latitude: value.latitude,
        instagramUser: value.instagramUser,
        imageUrl: value.imageUrl,
        directionDetails: value.directionDetails,
        direction: value.direction,
        closeTime: "08:00pm",
        schedule: value.schedule

      }
    })

    message.loading('Guardando cambios...', 10);
  }

  function cancel(e) {
    console.log(e);
  }
  return (
    <HeaderMenu backgroudColorS={Theme.color.primary} colorS={'#fff'} >
      <ContainerStore onSubmit={handleSubmit} >
        <Row justify="center">
          <Col xs={24} sm={22} md={22}>

            <Row justify="center" align="middle">
              <Col span={24} >
                <PhotoStore
                  imageUrl={values.imageUrl}
                  setFieldValue={setFieldValue}
                  idStore={store?.stores_id}
                />
              </Col>
            </Row>

            <Row gutter={[8, 16]} justify="center">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StoreData
                  name={values.name}
                  description={values.description}
                  email={values.email}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  errors={errors}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StoreContacts
                  numberPhone={values.numberPhone}
                  numberPhoneWs={values.numberPhoneWs}
                  webSite={values.webSite}
                  instagramUser={values.instagramUser}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  errors={errors}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Schedules
                  schedule={values.schedule}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col span={24}>
                <StoreDirection
                  name={values.name}
                  direction={values.direction}
                  directionDetails={values.directionDetails}
                  longitude={values.longitude}
                  latitude={values.latitude}
                  stores_id={store.stores_id}
                  apolloClient={apolloClient}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  errors={errors}
                />
              </Col>
              <Col xs={16} xl={12}>
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={22} sm={10} md={12} >
                    <Button
                      type='submint'
                      backgroundColor={Theme.color.acept}
                      color={'#fff'}
                      boxShadow={Theme.color.aceptBoxShadow}
                      hover={Theme.color.aceptHover}
                    >Guardar</Button>
                  </Col>
                  <Col xs={22} sm={10} md={12}>
                    <Popconfirm
                      title="¿Desea descartar los cambios?"
                      onConfirm={() => history.push('/inventory')}
                      onCancel={cancel}
                      okText="Si"
                      cancelText="No"
                    ><Button
                      backgroundColor={Theme.color.cancel}
                      color={'#fff'}
                      boxShadow={Theme.color.cancelBoxShadow}
                      hover={Theme.color.cancelHover}
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                    >Cancelar</Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContainerStore>
    </HeaderMenu>

  );
}

const ContainerStore = styled.form`
  margin-top:20px;
`;


