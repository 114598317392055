import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Input } from 'antd';
import { Theme } from '../../../genericComponents/globalStyles';
import CusttomModal from '../../../genericComponents/CusttomModal';
import SelectColor from './components/SelectColor';
import Button from '../../../genericComponents/Button';
import ImageCategorie from './components/ImageCategorie';

export default function AddCategory({
  isModalVisible,
  handleCancel,
  handleOkCategory,
  dataToEditCategory,
}) {



  const [nameCategory, setNameCategory] = useState();
  const [hexaColor, setHexaColor] = useState();
  const [imgUrl, setImgUrl] = useState();



  useEffect(() => {
    if (isModalVisible) {
      if (dataToEditCategory?.categoriesId) {
        setNameCategory(dataToEditCategory?.title);
        setHexaColor(dataToEditCategory?.exa_color);
        setImgUrl(dataToEditCategory?.image)
      } else {
        setNameCategory("");
        setHexaColor(Theme.color.primary);
        setImgUrl("")

      }
    }
  }, [
    isModalVisible ,
    dataToEditCategory?.categoriesId,
    dataToEditCategory?.title,
    dataToEditCategory?.exa_color,
    dataToEditCategory?.image,
    ])



  return (
    <>
      <CusttomModal
        title="Categoría"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}

      >
        <Row>
          <Col span={8}>
            <ImageCategorie 
              imgUrl={imgUrl}
              setImgUrl={setImgUrl}
              idCategory={dataToEditCategory?.categoriesId}
              nameCategory={dataToEditCategory?.title}
            />
          </Col>
          <Col span={16}>
            <Container>
              <InputCategorie
                placeholder="Nueva Categoria"
                value={nameCategory}
                onChange={(e) => setNameCategory(e.target.value)}

              />
            </Container>
            <br />
            <SelectColor 
              hexaColor={hexaColor} 
              setHexaColor={setHexaColor}
            
            />
            <br />
          </Col>
        </Row>

        <br />
        <ContainerButton>
            <ButtonSave
              backgroundColor={Theme.color.acept}
              color={"#fff"}
              onClick={
                ()=> handleOkCategory( dataToEditCategory?.categoriesId, nameCategory , hexaColor , imgUrl )
              }
            ><CustomLabel>Guardar</CustomLabel></ButtonSave>
        </ContainerButton>
      </CusttomModal>
    </>
  )
}


const InputCategorie = styled(Input)`
  width: 100%;
`

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 100px;
  
  
`;

const Container = styled.div`
  padding: 5px 10px;
`

const ButtonSave = styled.div`
  padding: 2px 10px;
  width: 50%;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  flex: ${({flex}) => flex || '1'};
  text-align: center;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props)=> props.boxShadow || 'none'};
  transition-duration: 0.4s;
  :hover{
    background-color: ${(props)=> props.hover};
    width: ${({widthHover}) => widthHover || '98%'};
  }
`;


const CustomLabel = styled.label`
  margin:auto;
  cursor: pointer;
  text-align: center;
  font-size: 1.5em;
  color: ${(props) => props.color || '#fff'};

`;





