import React from "react";
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Theme } from '../../genericComponents/globalStyles';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import StoresTable from "../components/SotoresTable";
import {  useQuery } from '@apollo/client';
import { STORES  } from '../../../helpers/graphqlQuerys';




export default function StoresScreens(){
  const {data , loading , refetch} = useQuery(STORES);
  return(
    <>
      <HeaderMenu backgroudColorSs={Theme.color.primary} colorSs={'#fff'}/>
      <ContainerStores>
      <Row justify="center" >
          <Col xs={22} sm={20} md={24} >
            <Row justify="center" >
              <Col xs={24} sm={22}  lg={22}>
                <StoresTable
                  key={data?.stores}
                  storesList={data?.stores || []}
                  refetchStoresList={refetch}
                  Storesloading={loading}
                />
              </Col>
            </Row><br/>
          </Col>
        </Row>
      </ContainerStores>
    </>
  )
}

const ContainerStores = styled.div`
  margin: auto;
  width: 100%;


`;