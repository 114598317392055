import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Theme } from '../../genericComponents/globalStyles';
import { EditFilled } from '@ant-design/icons';
import { Col, message, Row, Spin } from 'antd';
import AddSubcategory from './AddSubategory'
import Button from '../../genericComponents/Button';
import { useMutation } from '@apollo/client';
import { UPDATE_SUB_CATEGORY } from '../../../helpers/graphqlMutation';
import { CREATE_SUB_CATEGORY } from '../../../helpers/graphqlMutation';
import { Context } from '../../../globalContext/globalContext';
import { LoadingOutlined } from '@ant-design/icons';



export default function CardCategories({
  categories,
  showModal,
  setDataToEditCategory
}) {

  const [isModalSubCatVisible, setIsModalSubCatVisible] = useState(false);
  const { dispatch: { setCategories } } = useContext(Context);
  const [dataToEdit, setDataToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [update_sub_category, { loading: loadingSubCategoryEdit }] = useMutation(
    UPDATE_SUB_CATEGORY, {
    onCompleted: onCompletedUpdate, onError: onErrorUpdate,
  }
  );

  const [insert_sub_categories, { loading: loadingNewSubCategory }] = useMutation(
    CREATE_SUB_CATEGORY, {
    onCompleted: onCompletedInsert, onError: onErrorInsert,
  }
  );


  function onCompletedUpdate(data) {
    setIsModalSubCatVisible(false);
    message.success("¡Sub Categoria actualizada!")

    const subCategoryEdit = (
      categories?.map((cat) => {
        return {
          ...cat,
          sub_categories: cat?.sub_categories?.map((subCat) => {
            if (subCat?.sub_categories_id === data?.update_sub_categories_by_pk?.sub_categories_id) {
              return {
                ...subCat,
                title: data?.update_sub_categories_by_pk?.title
              }
            } else {
              return subCat
            }
          })
        }
      })
    )

    setCategories(subCategoryEdit);
  }

  function onErrorUpdate(data) {
    console.log("error", data);
  }


  function onCompletedInsert(data) {
    setIsModalSubCatVisible(false);
    message.success("¡Se agrego una nueva SubCategoria!");
    
    const newSubCategory = (
      categories?.map((cat)=>{
        if(cat?.categories_id === data?.insert_sub_categories?.returning[0]?.categories_id){
          return{
            ...cat,
            sub_categories:[
              ...cat.sub_categories ||  [],
              {
                sub_categories_id: data?.insert_sub_categories?.returning[0]?.sub_categories_id ,
                title: data?.insert_sub_categories?.returning[0]?.title,
                products: [],
              }
            ]
          }
        }else{
          return cat
        }
      })
    )

    setCategories(newSubCategory)

  }

  function onErrorInsert(data) {
    console.log(data)
  }


  const ModalVisibleModalSubCat = () => {
    setIsModalSubCatVisible(true);

  };


  const handleCancel = () => {
    setIsModalSubCatVisible(false);
  };

  const handleOkSubCategory = async (title, subCategoryId, categoryId) => {
    
    if(title){
      setIsModalSubCatVisible(false);

    if (loading) return
    setLoading(true)

    if (subCategoryId) {
      await update_sub_category({
        variables: {
          sub_categories_id: subCategoryId,
          title: title,
        }
      })

    } else {
      await insert_sub_categories({
        variables: {
          title: title,
          categories_id: categoryId,
        }
      })
    }
    }else{
      message.warning("Debe ingresar el nombre de la subcategoria")
    }
    
    

    setLoading(false)

  }



  return (
    <>

      <Row justify="center" >

        <Col xs={24} sm={22} md={22} lg={20} xl={18} >
          {
            categories?.map(
              (category) => (
                <Card borderColor={category?.exa_color} key={category?.categories_id} >
                  <Row justify="center">
                    <Col span={6}>
                      <ContainerImageCategory>
                        <ImageCategory
                          imgUrl={category?.image}
                          border={category?.exa_color}

                        />
                      </ContainerImageCategory>

                    </Col>
                    <Col span={18}>
                      <Row justify="center" >
                        <Col xs={22} sm={22} md={22} xl={22}>
                          <SectionCategories>
                            <Text fontWeigth={'600'} colorText={category?.exa_color}>{category?.title}</Text>
                            <NumProductosEditar>
                              <Text fontWeigth={'600'} colorText={category?.exa_color} >{category?.products.length}</Text>
                              <ButtonEdit>
                                <EditFilled
                                  onClick={
                                    () => {
                                      setDataToEditCategory({
                                        categoriesId: category?.categories_id,
                                        title: category?.title,
                                        exa_color: category?.exa_color,
                                        image: category?.image,
                                      })
                                      showModal()
                                    }
                                  }
                                />
                              </ButtonEdit>
                            </NumProductosEditar>
                          </SectionCategories>
                        </Col>

                        <Col xs={22} sm={22} md={22} xl={22}>

                          <SectionSubcategories>
                            {
                              loadingNewSubCategory || loadingSubCategoryEdit ? (
                                <>
                                  <SpinCusttom indicator={antIcon} />
                                </>
                              ) : (
                                <>{
                                  category?.sub_categories?.map(
                                  (subCategorie) => (
                                    <Subcategories key={subCategorie?.sub_categories_id}>
                                      <Text fontSize={'1rem'} >{subCategorie?.title}</Text>
                                      <NumProductosEditar>
                                        <Text fontSize={'1rem'} >{subCategorie?.products.length}</Text>
                                        <ButtonEdit>
                                          <EditFilled
                                            onClick={() => {
                                              setDataToEdit({
                                                categoriesId: category?.categories_id,
                                                subCategorieId: subCategorie?.sub_categories_id,
                                                title: subCategorie?.title
                                              })
                                              ModalVisibleModalSubCat();
                                            }}
                                          />
                                        </ButtonEdit>
                                      </NumProductosEditar>
                                    </Subcategories>
                                  )
                                )}</>
                              )
                            }

                          </SectionSubcategories>


                        </Col>



                      </Row>

                      <br />


                    </Col>

                    <Col span={24}>
                      <SectionButton>
                        <Button
                          backgroundColor={Theme.color.acept}
                          color={"#fff"}
                          width={'210px'}
                          widthHover={'200px'}
                          flex={'none'}
                          onClick={() => {
                            setDataToEdit({
                              categoriesId: category?.categories_id
                            })
                            ModalVisibleModalSubCat();
                          }}
                        >Agregar Subcategoria
                        </Button>
                      </SectionButton>

                    </Col>
                  </Row>

                </Card>

              )
            )
          }
        </Col>

      </Row>


      <AddSubcategory
        isModalVisible={isModalSubCatVisible}
        handleCancel={handleCancel}
        handleOkSubCategory={handleOkSubCategory}
        dataToEdit={dataToEdit}
      />

    </>
  )
}


const Card = styled.div`
  background-color: #fff;
  min-height: 35vh;
  margin: 20px auto;
  border-left: 20px solid ${(props) => props.borderColor || Theme.color.primary};
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(19, 91, 217, 0.16);
  padding: 10px ;


`;

const SectionCategories = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.h1`
  color: ${(props) => props.colorText || '#888787'};
  font-size: ${(props) => props.fontSize || '1.6rem'};
  font-weight: ${(props) => props.fontWeigth || '0'};
`;

const NumProductosEditar = styled.div`
  display: flex;

`;

const ButtonEdit = styled.h2`
  margin: 5px;
  margin-left: 25px;
  padding: 0;
  color: #9e9e9e;
  cursor: pointer;
  :hover{
    color:#737070;
  }

`;

const SectionSubcategories = styled(SectionCategories)`
  flex-direction: column;
  min-height: 20vh;
  max-height: 20vh;
  overflow: auto;
  padding: 5px;
`

const Subcategories = styled(SectionCategories)`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  
`;

const ContainerImageCategory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;


`

const ImageCategory = styled.div`
  background-image: url(${(props) => props.imgUrl}) ;
  width: 120px;
  height:120px;
  margin: auto;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${({ border }) => border || Theme.color.primary} ;
`


const SectionButton = styled.div`
  width: 99%;
  display: flex;
  justify-content: flex-end;
  padding: 0 1%;


`;

const SpinCusttom = styled(Spin)`&&&{
  .ant-spin-dot{
    color: ${Theme.color.primary};
    margin: 7vh auto;
  }

}
`