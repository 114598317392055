import React from 'react';
import styled from 'styled-components';
import HeaderAuth from '../../genericComponents/HeaderAuth';
import SingUp from '../components/SignUp';
import SingIn from '../components/SingIn';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Theme } from '../../genericComponents/globalStyles';


export default function AuthScreen(){
  return(
    <>
      <HeaderAuth/>
      <TabsAuth selectedTabClassName='is-selected'>
        <TabListAuth>
          <TabAuth >Iniciar Sesión</TabAuth>
          <TabAuth>Registro</TabAuth>
        </TabListAuth>
        <TabPanel><SingIn/></TabPanel>
        <TabPanel><SingUp/></TabPanel>
      </TabsAuth>
    </>
  )
}



const TabsAuth = styled(Tabs)`
  background-color: #fbfbfb;


`;

const TabListAuth = styled(TabList)`
  width: 100%;
  margin:auto;
  padding: 5px;
  display: flex;
  flex:1;
  flex-direction:row;
  background-color: ${Theme.color.primary};
  justify-content: center;
  @media (max-width: 800px){
    width: 100%;
  }
`;

const TabAuth = styled(Tab)`
  list-style: none; 
  margin: 0 13px;
  padding: 0px 15px;
  font-size: 1.8em;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-weight: 400;

    &.is-selected{
      color:  #fff;
      font-weight: 600;
      cursor: pointer;
    }
  
    :hover{
      color: #fff;
      font-weight: 900;
    }
`;
