import React, {  useState } from 'react'
import { Row, Col, Input } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import GoogleApiWrapper from '../../genericComponents/Maps';
import {
  Card,
  TextSubTitle,
  TextInputTitle,
  ContainerMaps
} from './generiStyled';
import WarningFomr from '../../genericComponents/WarningForm';
import { v4 as uuidv4 } from 'uuid';
import AutocompleteDirection from '../../genericComponents/AutocompleteDirection';
import { autocompletadoMaps, getDetailsAddress } from '../../../helpers/helperFuntion';





export default function StoreDirection({
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  longitude,
  latitude,
  direction,
  directionDetails,
  stores_id,
  name,
  apolloClient

}) {
  const [timer, setTimer] = useState();
  const [directionList, setDirectionList] = useState()
  const [directionEnabled, setDirectionEnabled] = useState(false)
  const sessionToken = uuidv4;
  const [listDitailsAdress, setListDitailsAdress] = useState()  



  function autoDirection(e) {
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        setDirectionList(await autocompletadoMaps(e.target.value, sessionToken, apolloClient))
      }, 800)
    )
    setFieldValue('direction', e.target.value);

  }

  async function  detailsAddress(e){
    setListDitailsAdress( await getDetailsAddress(e , sessionToken, apolloClient))
    setFieldValue("longitude" , listDitailsAdress?.lng )
    setFieldValue("latitude" ,  listDitailsAdress?.lat)
   }

  return (
    <Card>
      <Row gutter={[0, 16]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary}>Dirección</TextSubTitle>
        </Col>
        <Col xs={22} md={24}>
          <TextInputTitle>Ingrese su Dirección*</TextInputTitle>
          <AutocompleteDirection
            onChange={autoDirection}
            directionList={directionList}
            value={direction}
            onDirectionSelected={(direction, placeID) => {
              setFieldValue('direction', direction)
              setDirectionEnabled(false)
              detailsAddress(placeID)
            }}
            directionEnabled={directionEnabled}
            setDirectionEnabled={setDirectionEnabled}
          />

          {errors.direction && touched.direction && (<WarningFomr>{errors.direction} </WarningFomr>)}
        </Col>
        <Col xs={22} md={24}>
          <TextInputTitle>Informacion adicional (opcional)</TextInputTitle>
          <Input
            placeholder="Frente al centro comercial Sambil"
            value={directionDetails}
            onChange={(e) => setFieldValue('directionDetails', e.target.value)}
            onBlur={() => setFieldTouched('directionDetails', true)}
          />
          {
            errors.directionDetails && touched.directionDetails &&
            (<WarningFomr>{errors.directionDetails} </WarningFomr>)
          }
        </Col>
        <Col xs={22} md={24} >
          <ContainerMaps>
            <GoogleApiWrapper
              longitude={longitude}
              latitude={latitude}
              name={name}
              zoom= {18}
              />
          </ContainerMaps>
        </Col>
      </Row>
    </Card>
  );
}