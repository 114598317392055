import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import styled from 'styled-components';
import { Theme } from './globalStyles';

export default function HeaderSearch(props) {
  const {
    defaultValue,
    placeholder,
    setOpen,
    open,
    value,
    setValue,
    onSearch,
    options
  } = props;
  const inputRef = useRef(null);

  return (
    <ContainerHeaderSearch 
      onClick={() => {
        setOpen(true);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <SearchOutlined 
        key="Icon"
        style={{
          cursor: 'pointer',
        }}
      />
      <AutocompleteSearch
        width={`${open?"250px":"0"}`}
        key="AutoComplete"
        value={value}
        style={{
          height: 28,
          marginTop: -6,
        }}
        options={options}
        onChange={setValue}
      >
        <InputSearch className="hola"
          ref={inputRef}
          defaultValue={defaultValue}
          aria-label={placeholder}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (onSearch) {
                onSearch(value);
              }
            }
          }}
          onBlur={() => {
            setOpen(false);
          }}
        />
      </AutocompleteSearch>
    </ContainerHeaderSearch>
  );
};

const ContainerHeaderSearch = styled.div`
  width: 100%;
  text-align:center;
  color: ${Theme.color.sencondary};
  background-color: #ffffff;
  border:none;
  padding: 12px 20px;
  font-size: 1.5em;
  cursor:pointer;
  outline: none;
  margin: 0;
  display: flex;
  align-items: center;

  :hover{
    color: ${Theme.color.primary};
  }
`

const InputSearch = styled(Input)`
  border: none;
  height: 5vh;

  :focus{
    border-bottom: solid 1px ${Theme.color.primary};
  }
  
 
 
`

const AutocompleteSearch = styled(AutoComplete)`
  height: auto;
  width: ${({width})=> width || 0};
  min-width: 0;
  margin: auto ;
  margin-left: 10px;
  padding: 0;
  overflow: hidden;
  background: transparent;
  border-radius: 0;
  transition: width 0.3s, margin-left 0.3s;


`