import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from '../genericComponents/globalStyles';
import firebase from 'firebase';
import {
  CloseOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Context } from '../../globalContext/globalContext';


export default function MenuMonile(props) {
  let history = useHistory();
  const [view, setView] = useState(false);
  const { state: { user } } = useContext(Context);
  const { role: roleUser } = user;

  function SignOut() {
    firebase.auth().signOut()
      .then((r) => {
        history.replace('/auth');
      })
      .catch((e) => {
        console.log(e);
      })
  }

  if (!view) {
    return (
      <ContainerButton>
        <ButtonIcon onClick={() => setView(true)}>
          <MenuOutlined />
        </ButtonIcon>
      </ContainerButton>
    )

  } else {
    return (
      <>
        <Background onClick={() => setView(false)} />
        <ContainerMenu {...props} >
          <Row gutter={[0, 50]} >
            <Col span={24}>
              <ContainerButton>
                <ButtonIcon onClick={() => setView(false)}>
                  <CloseOutlined />
                </ButtonIcon>
              </ContainerButton>
            </Col>
            <Col span={24} >
              <UlNav>
                <li>
                  <ButtonNav
                    {...props}
                    onClick={() => history.push('/')}
                  >Inicio
                </ButtonNav>
                </li>
                {
                  roleUser === "admin" ? (
                    <>
                      <li>
                        <ButtonNav
                          {...props}
                          onClick={() => history.push('/admin/stores')}
                        >
                          Tiendas
                        </ButtonNav>
                      </li>
                      <li>
                        <ButtonNav
                          {...props}
                          onClick={() => history.push('/admin/categories')}
                        >
                          Categorias
                        </ButtonNav>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <ButtonNav
                          {...props}
                          onClick={() => history.push('/store')}
                        >Mi Tienda
                        </ButtonNav>
                      </li>

                      <li>
                        <ButtonNav
                          {...props}
                          onClick={() => history.push('/inventory')}
                        >Inventario
                        </ButtonNav>
                      </li>

                    </>
                  )
                }
                {/* <li>
                  <ButtonNav
                    {...props}
                    onClick={() => history.push('/setting')}
                  >Configuraciones
                  </ButtonNav>
                </li> */}

              <li>
                <ButtonNav {...props} onClick={() => history.push('/change/password')} >Cambio de contraseña</ButtonNav>
              </li>
              <li>
                <ButtonNav onClick={SignOut}>Cerrar Sesión</ButtonNav>
              </li>
              </UlNav>
            </Col>
          </Row>
      </ContainerMenu>
      </>


    )
  }

}

const ContainerMenu = styled.div`
  width: 40%;
  height: 100vh;
  position: fixed;
  right:0;
  box-shadow: 0 3px 20px 0 rgba(0,0,0,0.2);
  z-index: 1;
  background-color:#fff;
  display:none;
  @media(max-width:850px){
    display:block;
  }
  @media(max-width:600px){
    width: 60%;
  }

`;

const Background = styled.div`
  background-color: rgba(0,0,0,0.45);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index:0;
`;

const ContainerButton = styled.div``;

const UlNav = styled.ul`
  width: 85%;
  margin:auto;
  list-style: none;
  display:flex;
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;

  
`;

const ButtonNav = styled.button`
  width: 100%;
  text-align: start;
  color: ${Theme.color.sencondary};
  background-color: #ffffff;
  border: none;
  border-bottom: 0.1px solid ${Theme.color.sencondary} ;
  padding:  15px;
  font-size: 1.6em;
  cursor:pointer;
  outline: none;
  
`;

const ButtonIcon = styled.button`
  position: fixed;
  background-color: #fff;
  right: 0;
  border: none;
  padding: 5px 20px;
  font-size: 1.8em;
  font-weight: 500;
  color: ${Theme.color.sencondary};
  cursor:pointer;
  transition: 0.3s;
  @media(min-width:850px){
    display:none;
  }


`;

