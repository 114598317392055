import React from 'react';
import { Input} from 'antd';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';


export default function AutocompleteDirection(props) {
  const { 
    directionList,
    onDirectionSelected,
    directionEnabled,
    value,
    onChange,
    setDirectionEnabled,

  } = props
  function selectionDirection(direction){
    onDirectionSelected( direction.description , direction.placeId)

  }

  return (
    <>
      <ContainerInput>
        <InputDirection
          placeholder="Calle ,Avenida ,Cudad ,Estado ,Pais"
          required
          disabled={!directionEnabled}
          value= {value}
          onChange={onChange}
        />
        <Icon>
          { !directionEnabled && ( <EditOutlined  onClick={()=> setDirectionEnabled(true)}/>)}
        </Icon>
      </ContainerInput>
      { 
        directionEnabled && (
          <ContainerListDirection>
            {directionList?.map((direction) => (
              <BtnDirection
                key={direction.placeId}
                onClick={(e)=>{
                  e.preventDefault()
                  selectionDirection(direction)
                }}
              >{direction.description}</BtnDirection>
            ))
            }
          </ContainerListDirection>
        )
      }
    </>
  )
}

const ContainerInput = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
`
const InputDirection = styled(Input)`
 border: none;
`
const Icon = styled.div`
  border: none;
  display: flex;
  align-items:center;
  padding: 5px;

`
const ContainerListDirection = styled.div`
  width:100%;
  height: auto;
  color: #000;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-top: none;
  background-color: #f9f9f9;
`
const BtnDirection = styled.button`
  width: 100%;
  margin: auto;
  text-align: left;
  padding: 10px ;
  border: none;
  background-color: #0000;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 100px;
`