import React from 'react';
import HeaderMenu from '../../genericComponents/HeaderMenu';
import { Theme } from '../../genericComponents/globalStyles';
import PagConstrucion from '../../genericComponents/PagConstruccion';


export default function SettingScreen(){
  return(
        <HeaderMenu backgroudColorSe={Theme.color.primary} colorSe={'#fff'} >
          <PagConstrucion/>
        </HeaderMenu>
  );
}