import styled from 'styled-components';
import { Text } from '../../genericComponents/Text';

export const FormAuth = styled.form`
background-color: #fff;
margin: 40px auto ;
padding: 20px 40px;
border-radius: 4px;
box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
background-color: #ffffff;

`;

export const TextTitle = styled(Text)`
  font-size: 2em;
  font-weight: 500;
`;

export const TextInputTitle = styled(Text)`
  font-size: 1.3em;
  margin:15px;
  text-align: center;
`;


export const TextSubTitle = styled(Text)`
  font-size: 1.6em;
  margin-left: 5px;

`;
