import React from 'react'
import { Row, Col, Input } from 'antd';
import { Theme } from '../../genericComponents/globalStyles';
import {
  Card,
  TextSubTitle,
  TextInputTitle,
  CustomTextArea,
  ContainerInput,
  R,
} from './generiStyled';
import WarningFomr from '../../genericComponents/WarningForm';

export default function StoreData({
  name,
  description,
  email,
  setFieldValue,
  setFieldTouched,
  touched,
  errors
}) {
  return (
    <Card>
      <Row gutter={[8, 16]} justify="center">
        <Col span={24}>
          <TextSubTitle color={Theme.color.primary}>Datos de la tienda</TextSubTitle>
        </Col>
        <ContainerInput>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <TextInputTitle>Nombre de la tienda <R>*</R></TextInputTitle>
              <Input
                placeholder="Nombre de la tienda"
                required
                value={name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                onBlur={() => setFieldTouched('name', true)}
              />
              {errors.name && touched.name && (<WarningFomr>{errors.name} </WarningFomr>)}
            </Col>
            <Col xs={24} lg={12} xl={12}>
              <TextInputTitle>Correo electrónico <R>*</R></TextInputTitle>
              <Input
                disabled
                required
                value={email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={() => setFieldTouched('email', true)}
              />
              {errors.email && touched.email && (<WarningFomr>{errors?.email}</WarningFomr>)}
            </Col>
            <Col span={24}>
              <TextInputTitle>Descripción <R>*</R></TextInputTitle>
              <CustomTextArea
                showCount
                required
                autoSize={{ minRows: 2, maxRows: 4 }}
                minLength={30}
                maxLength={1200}
                value={description}
                onChange={(e) => setFieldValue('description', e.target.value)}
                onBlur={() => setFieldTouched('description', true)}
              />
              {errors.description && touched.description && (<WarningFomr>{errors?.description}</WarningFomr>)}
            </Col>
          </Row>
        </ContainerInput>
      </Row>
    </Card>

  );
}