import { createGlobalStyle } from "styled-components";
import Montserrat from '../../assets/Font/Montserrat-SemiBoldItalic.ttf';

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${Montserrat};

  }

  body{
    font-size: 12px;
  }


`;

export const Theme = {
  color: {
    primary: '#00aa99',
    sencondary: '#00dbc6',
    acept: '#39e377',
    aceptHover: '#27a555',
    aceptBoxShadow: '0px 0px 20px 0 #39e377',
    cancel: '#fe5151',
    cancelHover: '#e93232',
    cancelBoxShadow: '0px 0px 20px 0 #fe5151'
  }
}
