import { InputNumber } from 'antd';
import React from 'react';
import styled from 'styled-components';


export function InputNum({...props}){
  
  return(
    <NumberInput {...props}/>
  )
}

export const NumberInput = styled(InputNumber)`
  width:100%;

`;

