import dotenv from 'dotenv';
import firebase from 'firebase/app';
import 'antd/dist/antd.css'; 
import 'react-toastify/dist/ReactToastify.css';


// dotenv
if (process.env.NODE_ENV === "production") {
  dotenv.config({ path: `/../.env.production` });
} else {
  dotenv.config({ path: `/../.env` });
}


// firebase
const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};
firebase.initializeApp(config)